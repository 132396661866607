import React from 'react';

export default [
  {
    logo: require('src/assets/images/logos/origin-properties.svg'),
    title: 'Origin Properties',
    description: (
      <>
        <p>
          As a Vancouver real estate developer, we are rooted in our city and in
          the communities we call home. We believe that the homes we build
          should not only benefit our homeowners, but also the greater
          neighbourhood that they are part of. By creating great multi-family
          homes that respond to the needs of the neighbourhood, and designing
          them be a cohesive part of the community, we strive to contribute to
          our city, and create solutions that will benefit the generations to
          come.{' '}
        </p>
        <p>
          This belief is founded on the philosophy that our homes are more than
          just somewhere to live. That they are special places that will be part
          of our homeowners’ lives. Places where they will start relationships,
          where they will raise their children, and welcome grandchildren. By
          creating homes that add meaning and value to the lives of our
          homeowners, we believe we can build a stronger future for everyone.
        </p>
      </>
    ),
    website: 'http://originproperties.ca/',
  },
  {
    logo: require('src/assets/images/logos/nsda-logo.png'),
    title: 'NSDA Architects',
    description: (
      <p>
        Established in 1976, NSDA Architects is a full service architectural
        firm located in Vancouver, BC. Founded on the principles of personalized
        service to our clients, a teamwork approach, a commitment to quality,
        and the effective delivery of our client’s projects, NSDA is a six-time
        recipient of the Lieutenant Governor’s award, and prides itself on the
        diversity of its portfolio. It is this diversity – in scope, complexity
        and budget – that allows us to grow both professionally and creatively.
      </p>
    ),
    website: 'https://www.nsda.bc.ca/',
  },
  {
    logo: require('src/assets/images/logos/coi-logo.svg'),
    title: 'Cristina Oberti Interior Design',
    description: (
      <>
        <p>
          Specializing in multi-unit residential homes and commercial designs,
          Cristina offers leading industry experience, extensive knowledge and a
          systematic design process. Because each project has its own story, we
          create interiors that convey a unique, coherent yet stylish message.
        </p>
      </>
    ),
    website: 'https://cointeriordesign.com/',
  },
];
