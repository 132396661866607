import React from 'react';
import styled from 'styled-components';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';

import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const Nextbar = ({ text, to }) => {
  return (
    <Root to={to}>
      <Color>{text}</Color>
    </Root>
  );
};

Nextbar.propTypes = {
  text: Proptypes.string,
  to: Proptypes.string,
};

export default Nextbar;

const Root = styled(Link)`
  width: 100%;
  height: ${vwMobile(51)};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.header};
  font-size: ${vwMobile(16)};
  font-weight: normal;
  letter-spacing: ${vwMobile(0.4)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.text};
  border-top: 1px solid ${({ theme }) => theme.color.darkBlue};
  border-bottom: 1px solid ${({ theme }) => theme.color.darkBlue};
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(16)};
    letter-spacing: ${vwTablet(0.4)};
    height: ${vwTablet(51)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(16)};
    letter-spacing: ${vw(0.4)};
    height: ${vw(77)};
    max-width: ${vw(1234)};
    margin: 0 auto;
  }
`;

const Color = styled.div`
  background-color: #f0f6f5;
  height: ${vwMobile(35)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(35)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(57)};
    &:hover {
      background-color: ${({ theme }) => theme.color.offWhite};
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    }
  }
`;
