export default {
  color: {
    primary: '#00d8ff',
    silver: '#c0d9d7',
    darkBlue: '#1a373c',
    earth: '#967a36',
    blueGray: '#51666a',
    desert: '#d0b063',
    offWhite: '#f7f7f7',
    text: '#242424',
    textLightBlue: '#6d8180',
  },
  fonts: {
    header: 'Glosa Display Roman',
    main: 'Lato, sans-serif',
  },
};
