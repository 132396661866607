import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vwMobile, vwTablet } from 'src/styles/utils';

import Button from '../elements/Button';

const MobileLevelSelect = ({ activeLevel, handleLevel }) => {
  return (
    <Root>
      <p>Level Select</p>
      <div>
        <StyledButton
          text="2"
          handleClick={() => handleLevel(0)}
          active={activeLevel === 0}
        />
        <StyledButton
          text="3"
          handleClick={() => handleLevel(1)}
          active={activeLevel === 1}
        />
        <StyledButton
          text="4"
          handleClick={() => handleLevel(2)}
          active={activeLevel === 2}
        />
      </div>
    </Root>
  );
};

MobileLevelSelect.propTypes = {
  activeLevel: PropTypes.number,
  handleLevel: PropTypes.func,
};

export default MobileLevelSelect;

const Root = styled.div`
  @media (min-width: ${media.mdup}) {
    display: none;
  }
  p {
    font-family: ${({ theme }) => theme.fonts.main};
    font-weight: bold;
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(1.8)};
    text-transform: uppercase;
    text-align: center;
    color: ${({ theme }) => theme.color.blueGray};
    margin-bottom: ${vwMobile(15)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(1.8)};
      margin-bottom: ${vwTablet(15)};
    }
  }
  div {
    display: flex;
    justify-content: space-around;
    @media (min-width: ${media.xsup}) {
      max-width: ${vwTablet(237)};
      margin: 0 auto;
    }
  }
`;

const StyledButton = styled(Button)`
  width: ${vwMobile(30)};
  height: ${vwMobile(30)};
  padding: ${vwMobile(3)};
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(30)};
    height: ${vwTablet(30)};
    padding: ${vwTablet(3)};
  }
  div {
    width: 100%;
    height: 100%;
  }
`;
