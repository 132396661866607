import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Parallax } from 'react-scroll-parallax';
import { vwMobile, vw, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import Card from './Card';

const Hero = ({ title, subTitle, image, header, text }) => {
  return (
    <Root>
      <Image image={image}>
        <Parallax y={[15, -10]}>
          <StyledCard title={title} subTitle={subTitle} />
        </Parallax>
      </Image>
      <Text>
        <h1>{header}</h1>
        <p>{text}</p>
      </Text>
    </Root>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  header: PropTypes.string,
  text: PropTypes.string,
};

export default Hero;

const Root = styled.div``;

const Image = styled.div`
  height: ${`calc(100vh - ${vwMobile(20)})`};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  padding-top: ${vwMobile(30)};
  @media (min-width: ${media.mdup}) {
    height: ${vw(650)};
    padding-top: ${vw(420)};
  }
`;

const StyledCard = styled(Card)`
  margin: 0 auto;
  @media (min-width: ${media.mdup}) {
    margin: 0 ${vw(78)};
  }
`;

const Text = styled.div`
  text-align: center;
  margin-top: ${vwMobile(54)};
  padding: 0 ${vwMobile(10)};
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(54)};
    padding: 0 ${vwMobile(70)};
  }
  @media (min-width: ${media.mdup}) {
    text-align: left;
    padding: 0;
    margin-left: ${vw(680)};
    margin-top: ${vw(117)};
  }
  h1 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vwMobile(18)};
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.56;
    letter-spacing: ${vwMobile(1)};
    margin-bottom: ${vwMobile(24)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      letter-spacing: ${vwTablet(1)};
      font-size: ${vwTablet(18)};
      margin-bottom: ${vwTablet(24)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(24)};
      line-height: 1.42;
      letter-spacing: ${vw(2.4)};
    }
  }
  p {
    @media (min-width: ${media.mdup}) {
      max-width: ${vw(486)};
    }
  }
`;
