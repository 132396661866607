import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import Page from 'components/Page';
import Nextbar from 'components/Nextbar';
import Map from './elements/Map';

class Contact extends Component {
  render() {
    return (
      <Page>
        <Info>
          <h1>Contact Us</h1>
          <img
            src={require('src/assets/images/icons/plant-icon-mobile.svg')}
            alt="Contact"
          />
          <div>
            <div>
              <h2>Presentation Centre</h2>
              <a
                href="https://www.google.com/maps/place/2090+Alma+St,+Vancouver,+BC+V6R+3P9/@49.2677713,-123.1879283,17z/data=!3m1!4b1!4m5!3m4!1s0x5486725606f3942d:0x2662b0b2f4950d72!8m2!3d49.2677678!4d-123.1857343"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>2090 Alma St, Vancouver, BC</p>
              </a>
            </div>
            <div>
              <h2>Email</h2>
              <a href="mailto:info@thearbutus.com">
                <p>info@thearbutus.com</p>
              </a>
            </div>
            <div>
              <h2>Call</h2>
              <a href="tel:604-307-0607">
                <p>604-307-0607</p>
              </a>
            </div>
          </div>
        </Info>
        <Map />
        <Nextbar text="Next: Register" to="/register" />
      </Page>
    );
  }
}

export default Contact;

const Info = styled.div`
  text-align: center;
  @media (min-width: ${media.mdup}) {
    text-align: left;
  }
  h1 {
    margin-bottom: ${vwMobile(5)};
    margin-top: ${vwMobile(32)};
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(30)};
      letter-spacing: ${vw(0.9)};
      text-align: center;
      margin-top: ${vw(80)};
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vwMobile(16)};
    line-height: 1.88;
    letter-spacing: ${vwMobile(0.9)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.darkBlue};
    margin-top: ${vwMobile(40)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(0.9)};
      margin-top: ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(20)};
      letter-spacing: ${vw(1.1)};
      margin-top: 0;
    }
  }
  p {
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(14)};
    font-weight: normal;
    line-height: 2;
    letter-spacing: ${vwMobile(0.4)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.4)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(0.5)};
      line-height: 1.75;
    }
  }
  img {
    margin-bottom: ${vwMobile(5)};
    @media (min-width: ${media.mdup}) {
      display: block;
      margin: 0 auto;
      width: ${vw(56)};
      height: ${vw(41)};
      object-fit: contain;
    }
  }
  > div {
    @media (min-width: ${media.mdup}) {
      display: flex;
      margin: ${vw(70)} ${vw(76)} ${vw(60)};
      > div {
        margin-right: ${vw(100)};
      }
    }
  }
`;
