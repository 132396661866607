import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Button = props => {
  const { active, text, handleClick } = props;
  return (
    <Root {...props} onClick={handleClick} active={active}>
      <div>{text}</div>
    </Root>
  );
};

Button.propsTypes = {
  active: PropTypes.bool,
  text: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Button;

const Root = styled.div`
  background-color: ${props =>
    props.active ? props.theme.color.silver : props.theme.color.offWhite};
  box-shadow: ${props =>
    props.active
      ? '0 0 4px 0 rgba(0, 0, 0, 0)'
      : '0 0 4px 0 rgba(0, 0, 0, 0.4)'};
  padding: ${vwMobile(5)};
  font-size: ${vwMobile(10)};
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.main};
  letter-spacing: ${vwMobile(1.8)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.blueGray};
  cursor: pointer;
  transition: 0.3s ease;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(5)};
    font-size: ${vwTablet(10)};
    letter-spacing: ${vwTablet(1.8)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(4)};
    font-size: ${vw(10)};
    letter-spacing: ${vw(1.8)};
  }
  div {
    border: ${props =>
      props.active
        ? `1px solid ${props.theme.color.silver}`
        : `1px solid ${props.theme.color.darkBlue}`};
    padding: ${vwMobile(5)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: ${media.xsup}) {
      padding: ${vwTablet(5)};
    }
    @media (min-width: ${media.mdup}) {
      padding: ${vw(5)};
    }
  }
`;
