import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'src/styles/media';
import { vw, vwMobile } from 'src/styles/utils';

import Floorplate from '../elements/Floorplate';
import data from 'src/data/floorplates/levels';

const LevelSelect = ({
  handlePlan,
  isPlanActive,
  activeLevel,
  floorplanData,
}) => {
  return (
    <Root>
      {data.map((plate, index) => (
        <Level key={index} active={index === activeLevel}>
          <Floorplate
            plate={plate}
            handlePlan={handlePlan}
            floorplanData={floorplanData}
            isPlanActive={isPlanActive}
          />
          <img
            src={require('src/assets/images/icons/compass.svg')}
            alt="Compass"
            className="compass"
          />
        </Level>
      ))}
    </Root>
  );
};

LevelSelect.propTypes = {
  handlePlan: PropTypes.func,
  isPlanActive: PropTypes.func,
  activeLevel: PropTypes.number,
  floorplanData: PropTypes.array,
};

export default LevelSelect;

const Root = styled.div`
  width: 100%;
  height: ${vwMobile(140)};
  position: relative;
  margin-bottom: ${vwMobile(15)};
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(85)};
    margin-bottom: ${vw(125)};
    height: ${vw(360)};
  }
`;

const Level = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  transition: 0.3s ease;
  .compass {
    width: ${vwMobile(20)};
    height: ${vwMobile(12)};
    position: absolute;
    bottom: 0;
    left: ${vwMobile(5)};
    @media (min-width: ${media.mdup}) {
      width: ${vw(61)};
      height: ${vw(38)};
      left: ${vw(-80)};
      bottom: 0;
    }
  }
`;
