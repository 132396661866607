import cookie from 'cookie-parse';
import LCrypt from 'lcrypt';
import appConfig from 'src/config/app.conf';


export default async function Insight(arg = {}) {

console.log("dfasdfasdfasd")
  const project = appConfig.dbProject
  const name = arg.name || '' 
  const email = arg.email || ''
  const pageName = arg.pageName || window.location.pathname
  const queryString = arg.queryString || window.location.search
  const referrer = arg.referrer ||  document.referrer


  const cookies = cookie.parse(document.cookie);
  const lcrypt = new LCrypt('aHUyb25wZzU4ZnQ2eG9xOHdmOXh0YWp2bnpmdXZkYWQ=');

  
  let param = {
    a: lcrypt.encode(project),
    b: lcrypt.encode(name),
    c: lcrypt.encode(email),
    d: lcrypt.encode(pageName),
    e: lcrypt.encode(queryString),
    f: lcrypt.encode(referrer),
    g: '',
    h: 'qwe',
  };

  try {
    param["i"] = lcrypt.encode(cookies._ga);
  } catch(e) {
    param["i"] = lcrypt.encode(' ');
  }

  try {
    param["j"] = lcrypt.encode(cookies._gid);
  } catch(e) {
    param["j"] = lcrypt.encode(' ');
  }

  try {
    param["k"] = lcrypt.encode(cookies._hjid);
  } catch(e) {
    param["k"] = lcrypt.encode(' ');
  }
  if(window.location.hostname !== 'localhost'){
  await fetch('https://i.bamcommunications.ca/server/project/public/api/intelligence', {
    method: 'POST',
    mode: 'no-cors', 
    cache: 'no-cache', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(param),
  });
  }
  return arg
}
