import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const filters = ['1 Bed', '2 Bed'];
const levels = ['Level 2', 'Level 3', 'Level 4'];

const Filters = ({
  activeFilter,
  activeLevel,
  activeType,
  handleFilter,
  handleLevel,
  clearFilter,
  handleDropdown,
  isDropdownOpen,
}) => {
  return (
    <Root>
      <DesktopLevels active={activeType !== 1}>
        <h3>Select a Level</h3>
        <List>
          {levels.map((level, index) => (
            <Item
              key={index}
              onClick={() => handleLevel(index)}
              active={index === activeLevel}
            >
              {level}
            </Item>
          ))}
        </List>
      </DesktopLevels>
      <h3>Filters</h3>
      <Clear onClick={clearFilter} active={activeFilter !== null}>
        Clear{' '}
        <img src={require('src/assets/images/icons/clear.svg')} alt="Clear" />
      </Clear>
      <div className="dropdown">
        <Select onClick={handleDropdown}>
          {activeFilter !== null ? filters[activeFilter] : 'Select One'}
          <img
            src={require('src/assets/images/icons/dropdown-icon.svg')}
            alt="Select One"
          />
        </Select>
        <List isOpen={isDropdownOpen}>
          {filters.map((item, index) => (
            <Item
              key={index}
              onClick={() => handleFilter(index)}
              active={index === activeFilter}
            >
              {item}
            </Item>
          ))}
        </List>
      </div>
    </Root>
  );
};

Filters.propTypes = {
  activeFilter: PropTypes.number,
  activeLevel: PropTypes.number,
  activeType: PropTypes.number,
  handleFilter: PropTypes.func,
  handleLevel: PropTypes.func,
  clearFilter: PropTypes.func,
  handleDropdown: PropTypes.func,
  isDropdownOpen: PropTypes.bool,
};

export default Filters;

const Root = styled.div`
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(35)};
  }
  h3 {
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(10)};
    font-weight: bold;
    letter-spacing: ${vwMobile(1.8)};
    text-transform: uppercase;
    text-align: center;
    margin: ${vwMobile(4)} 0 ${vwMobile(12)};
    color: ${({ theme }) => theme.color.blueGray};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(1.8)};
      margin: ${vwTablet(4)} 0 ${vwTablet(12)};
    }
    @media (min-width: ${media.mdup}) {
      font-family: ${({ theme }) => theme.fonts.header};
      font-size: ${vw(17)};
      font-weight: normal;
      line-height: 1.76;
      text-align: left;
      letter-spacing: ${vw(1.7)};
      color: ${({ theme }) => theme.color.darkBlue};
      margin: 0;
    }
  }
  .dropdown {
    position: relative;
    margin-bottom: ${vwMobile(27)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(27)};
    }
  }
`;

const Select = styled.div`
  border: 1px solid ${({ theme }) => theme.color.blueGray};
  width: ${vwMobile(237)};
  height: ${vwMobile(35)};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${vwMobile(27)};
  font-size: ${vwMobile(10)};
  font-weight: bold;
  letter-spacing: ${vwMobile(1.8)};
  text-transform: uppercase;
  position: relative;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(237)};
    height: ${vwTablet(35)};
    padding: 0 ${vwTablet(27)};
    font-size: ${vwTablet(10)};
    letter-spacing: ${vwTablet(1.8)};
  }
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const DesktopLevels = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: ${props => (props.active ? 'block' : 'none')};
    margin-bottom: ${vw(30)};
    h3 {
      margin-bottom: ${vw(18)};
    }
  }
`;

const List = styled.div`
  border: ${props =>
    props.isOpen ? `1px solid ${props.theme.color.blueGray}` : '0px solid'};
  border-top: 0;
  background-color: ${({ theme }) => theme.color.offWhite};
  width: ${vwMobile(237)};
  position: absolute;
  top: 100%;
  left: ${vwMobile(23.5)};
  max-height: ${props => (props.isOpen ? '500px' : 0)};
  transition: 0.3s ease-in-out;
  overflow: hidden;
  z-index: 10;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(237)};
    left: 50%;
    transform: translateX(-50%);
  }
  @media (min-width: ${media.mdup}) {
    max-height: none;
    position: relative;
    top: none;
    left: none;
    background-color: transparent;
  }
`;

const Item = styled.p`
  height: ${vwMobile(35)};
  display: flex;
  align-items: center;
  font-size: ${vwMobile(10)};
  font-weight: normal;
  letter-spacing: ${vwMobile(1.8)};
  text-transform: uppercase;
  padding: 0 ${vwMobile(27)};
  cursor: pointer;
  color: ${({ theme }) => theme.color.blueGray};
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(35)};
    font-size: ${vwTablet(10)};
    letter-spacing: ${vwTablet(1.8)};
    padding: 0 ${vwTablet(27)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(12)} ${vw(27)};
    font-size: ${vw(10)};
    font-weight: bold;
    border: 1px solid ${({ theme }) => theme.color.darkBlue};
    margin-bottom: ${vw(5)};
    width: ${vw(153)};
    height: ${vw(35)};
    transition: 0.3s ease;
    background-color: ${props => (props.active ? 'white' : 'transparent')};
    &:hover {
      background-color: white;
    }
  }
`;

const Clear = styled.h5`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vw(10)};
    font-weight: bold;
    letter-spacing: ${vw(1.8)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.blueGray};
    margin: ${vw(6)} 0 ${vw(9)};
    cursor: pointer;
    opacity: ${props => (props.active ? 1 : 0)};
    pointer-events: ${props => (props.active ? 'all' : 'none')};
    transition: 0.3s ease;
  }
  img {
    width: ${vw(10)};
    height: ${vw(10)};
    margin-left: ${vw(6)};
  }
`;
