import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import imgDropdown from 'src/assets/images/icons/dropdown.svg';

const Select = ({ htmlId, label, required, options, name }) => (
  <Root>
    <Label htmlFor={htmlId} className="form-label">
      {label}
    </Label>
    <select id={htmlId} required={required} name={name}>
      <option value="">- Select One -</option>
      {options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.title}
        </option>
      ))}
    </select>
  </Root>
);

export default Select;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => `${props.width}%`};
  position: relative;
  &::after {
    content: '';
    width: ${vwMobile(20)};
    height: ${vwMobile(10)};
    background: url(${imgDropdown});
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    transform: 'translateY(${vwMobile(-3)})';
    margin-bottom: ${vwMobile(2)};
    right: ${vwMobile(26)};
    pointer-events: none;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(20)};
      height: ${vwTablet(10)};
      transform: 'translateY(${vwTablet(-3)})';
      margin-bottom: ${vwTablet(2)};
      right: ${vwTablet(26)}
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(20)};
      height: ${vw(10)};
      transform: 'translateY(${vw(-3)})';
      margin-bottom: ${vw(2)};
      right: ${vw(26)}
    }
  }

  select {
    cursor: pointer;

    -webkit-appearance: none;
    height: ${vwMobile(40)};
    border: 1px solid rgba(36, 36, 36, 0.74);
    border-radius: 0;
    background: white;
    font-size: ${vwMobile(14)};
    font-family: Lato;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
    padding: ${vwMobile(12)} ${vwMobile(4)};
    margin-bottom: ${vwMobile(24)};
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(40)};
      font-size: ${vwTablet(14)};
      padding: ${vwTablet(12)} ${vwTablet(4)};
      margin-bottom: ${vwTablet(24)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(40)};
      font-size: ${vw(14)};
      padding: ${vw(12)} ${vw(4)};
      margin-bottom: ${vw(24)};
      margin-right: ${vw(10)};
    }
  }
`;

const Label = styled.label`
  opacity: 0.75;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  padding-left: ${vwMobile(4)};
  padding-bottom: ${vwMobile(9)};
  @media (min-width: ${media.xsup}) {
    padding-left: ${vwTablet(4)};
    padding-bottom: ${vwTablet(9)};
  }
  @media (min-width: ${media.mdup}) {
    padding-left: ${vw(4)};
    padding-bottom: ${vw(9)};
  }
`;
