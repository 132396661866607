import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import Page from 'components/Page';
import Header from './sections/Header';
import Filters from './sections/Filters';
import TypeSelect from './sections/TypeSelect';
import LevelSelect from './sections/LevelSelect';
import Nextbar from 'components/Nextbar';
import Popup from './sections/Popup';
import MobileLevelSelect from './sections/MobileLevelSelect';

import dataFloorplans from 'src/data/floorplans';

class Floorplans extends Component {
  constructor(props) {
    super(props);
    this.$slider = React.createRef();
  }

  state = {
    activeType: 0,
    activeFilter: null,
    activeLevel: 0,
    activePlan: 0,
    isDropdownOpen: false,
    isPopupOpen: false,
  };

  handleType = index => this.setState({ activeType: index });

  handleLevel = index => this.setState({ activeLevel: index });

  handleFilter = index => {
    if (index === this.state.activeFilter) {
      this.setState({ activeFilter: null, isDropdownOpen: false });
    } else {
      this.setState({ activeFilter: index, isDropdownOpen: false });
    }
  };

  clearFilter = () => this.setState({ activeFilter: null });

  handleDropdown = () =>
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });

  handlePlan = index => {
    this.$slider.current.slickGoTo(index, true);
    return this.setState({ activePlan: index, isPopupOpen: true });
  };

  closePopup = () => this.setState({ isPopupOpen: false });

  isPlanActive = bedroom => {
    if (this.state.activeFilter === null) {
      return true;
    } else if (this.state.activeFilter === 0 && bedroom === 1) {
      return true;
    } else if (this.state.activeFilter === 1 && bedroom === 2) {
      return true;
    } else {
      return false;
    }
  };

  handleNextPlan = () => {
    if (this.state.activePlan === dataFloorplans.length - 1) {
      return this.setState({ activePlan: 0 });
    } else {
      this.setState({ activePlan: this.state.activePlan + 1 });
    }
  };

  handlePrevPlan = () => {
    if (this.state.activePlan === 0) {
      return this.setState({ activePlan: dataFloorplans.length - 1 });
    } else {
      return this.setState({ activePlan: this.state.activePlan - 1 });
    }
  };

  render() {
    return (
      <Root isPopupOpen={this.state.isPopupOpen}>
        <Page>
          <Container>
            <Header
              activeType={this.state.activeType}
              handleType={this.handleType}
            />
            <div className="desktop">
              <Filters
                activeFilter={this.state.activeFilter}
                activeLevel={this.state.activeLevel}
                handleLevel={this.handleLevel}
                handleFilter={this.handleFilter}
                clearFilter={this.clearFilter}
                isDropdownOpen={this.state.isDropdownOpen}
                handleDropdown={this.handleDropdown}
                activeType={this.state.activeType}
              />
              {this.state.activeType === 0 ? (
                <>
                  <LevelSelect
                    activeLevel={this.state.activeLevel}
                    handlePlan={this.handlePlan}
                    floorplanData={dataFloorplans}
                    isPlanActive={this.isPlanActive}
                  />
                  <MobileLevelSelect
                    activeLevel={this.state.activeLevel}
                    handleLevel={this.handleLevel}
                  />
                </>
              ) : (
                <TypeSelect
                  data={dataFloorplans}
                  isPlanActive={this.isPlanActive}
                  handlePlan={this.handlePlan}
                />
              )}
            </div>
          </Container>
          <Popup
            active={this.state.isPopupOpen}
            data={dataFloorplans}
            closePopup={this.closePopup}
            sliderRef={this.$slider}
            nextPlan={this.$slider.current && this.$slider.current.slickNext}
            prevPlan={this.$slider.current && this.$slider.current.slickPrev}
          />
          <Nextbar to="/building" text="Next: Building" />
        </Page>
      </Root>
    );
  }
}

export default Floorplans;

const Root = styled.div`
  overflow: ${props => (props.isPopupOpen ? 'hidden' : 'auto')};
  max-height: ${props => (props.isPopupOpen ? '100vh' : 'auto')};
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.offWhite};
  padding: ${vwMobile(20)} ${vwMobile(8)};
  margin-bottom: ${vwMobile(50)};
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(50)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(56)} ${vw(76)};
    margin-bottom: ${vw(75)};
    background-image: url(${require('src/assets/images/floorplans/floorplans-leaf.svg')});
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 90%;
  }
  .desktop {
    @media (min-width: ${media.mdup}) {
      display: flex;
    }
  }
`;
