import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const Popup = ({ active, nextPlan, prevPlan, data, closePopup, sliderRef }) => {
  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  };
  return (
    <Root active={active}>
      <img
        src={require('src/assets/images/icons/close-icon.svg')}
        alt="Close Popup"
        onClick={closePopup}
        className="close"
      />
      <Container {...settings} ref={sliderRef}>
        {data.map((plan, index) => (
          <Plan key={index}>
            <div>
              <div className="header">
                <h2>Plan {plan.id}</h2>
                <ul>
                  <li>
                    {plan.bed} Bed {plan.den && '+ Den'}
                  </li>
                  {plan.roof && <li>Roof Deck</li>}
                </ul>
              </div>
              <div className="content">
                <h3>Size</h3>
                <Sizes>
                  <div>
                    <p>Interior</p>
                    <h4>{plan.interior} sq ft</h4>
                  </div>
                  {plan.balcony && (
                    <div>
                      <p>Balcony</p>
                      <h4>{plan.balcony} sq ft</h4>
                    </div>
                  )}
                  {plan.roof && (
                    <div>
                      <p>Roof Deck</p>
                      <h4>{plan.roof} sq ft</h4>
                    </div>
                  )}
                </Sizes>
                <p>Total</p>
                <h4 className="total">{plan.total} sq ft</h4>
                <img
                  src={require(`src/assets/images/floorplans/plans/${
                    plan.id
                  }.png`)}
                  alt={plan.id}
                  className="plan-image"
                />
                <img
                  src={require('src/assets/images/icons/north-icon.svg')}
                  alt="North"
                  className="north"
                />
                <img
                  src={require(`src/assets/images/floorplans/keyplates/${
                    plan.id
                  }.png`)}
                  alt={plan.id}
                  className="plate-image"
                />
                <NextPlan>
                  <h3 onClick={prevPlan} className="left">
                    <img
                      src={require('src/assets/images/icons/arrow-left.svg')}
                      alt="Previous Plan"
                    />
                    Previous Plan
                  </h3>
                  <h3 onClick={nextPlan}>
                    Next Plan
                    <img
                      src={require('src/assets/images/icons/arrow-right.svg')}
                      alt="Next Plan"
                    />
                  </h3>
                </NextPlan>
              </div>
            </div>
            <div className="desktop-plan">
              <img
                src={require(`src/assets/images/floorplans/plans/${
                  plan.id
                }.png`)}
                alt={plan.id}
                className="plan"
              />
              <img
                src={require('src/assets/images/icons/north-icon.svg')}
                alt="North"
                className="compass"
              />
            </div>
          </Plan>
        ))}
      </Container>
    </Root>
  );
};

Popup.propTypes = {
  active: PropTypes.bool,
  nextPlan: PropTypes.func,
  prevPlan: PropTypes.func,
  data: PropTypes.array,
  closePopup: PropTypes.func,
  sliderRef: PropTypes.object,
};

export default Popup;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: ${vwMobile(10)};
  background-color: rgba(0, 0, 0, 0.69);
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  transition: 0.3s ease;
  z-index: 100;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(10)};
  }
  @media (min-width: ${media.mdup}) {
    background-color: rgba(0, 0, 0, 0.23);
  }
  .close {
    position: absolute;
    top: ${vwMobile(25)};
    right: ${vwMobile(25)};
    z-index: 200;
    cursor: pointer;
    @media (min-width: ${media.xsup}) {
      top: ${vwTablet(40)};
      right: ${vwTablet(40)};
    }
    @media (min-width: ${media.xsup}) {
      top: ${vw(45)};
      right: ${vw(90)};
      width: ${vw(34)};
      height: ${vw(34)};
    }
  }
  .slick-slide,
  .slick-track,
  div,
  .slick-cloned {
    outline: none !important;
  }
`;

const Container = styled(Slider)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.offWhite};
  @media (min-width: ${media.mdup}) {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    padding: ${vw(45)} ${vw(90)};
    height: 100%;
    overflow: scroll;
  }
`;

const Plan = styled.div`
  width: 100%;
  height: 100%;
  @media (min-width: ${media.mdup}) {
    display: flex !important;
    max-height: 100%;
    overflow: scroll;
    padding-bottom: ${vw(45)};
  }
  .header {
    background-color: ${({ theme }) => theme.color.silver};
    padding: 0 ${vwMobile(13)} ${vwMobile(10)};
    height: ${vwMobile(88)};
    @media (min-width: ${media.xsup}) {
      padding: 0 ${vwTablet(13)} ${vwTablet(10)};
      height: ${vwTablet(88)};
    }
    @media (min-width: ${media.mdup}) {
      padding: 0;
      margin-top: ${vw(60)};
      background-color: transparent;
      height: ${vw(133)};
    }
  }
  li {
    list-style-type: none;
    font-size: ${vwMobile(10)};
    line-height: 1.7;
    letter-spacing: ${vwMobile(1)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.darkBlue};
    display: flex;
    align-items: center;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(1)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(12)};
      letter-spacing: ${vw(2)};
    }
    &::before {
      content: '∙';
      padding-right: ${vwMobile(10)};
      @media (min-width: ${media.xsup}) {
        padding-right: ${vwTablet(10)};
      }
      @media (min-width: ${media.xsup}) {
        padding-right: ${vw(10)};
      }
    }
  }
  img {
    margin-top: ${vwMobile(8)};
    @media (min-width: ${media.xsup}) {
      margin-top: ${vwTablet(8)};
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vwMobile(30)};
    font-weight: normal;
    letter-spacing: vwTablet ${vwMobile(1)};
    line-height: normal;
    color: #c9af74;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(30)};
      letter-spacing: ${vwTablet(1)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(40)};
      letter-spacing: ${vw(1.3)};
    }
  }
  h3 {
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(10)};
    font-weight: bold;
    letter-spacing: ${vwMobile(1.8)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.blueGray};
    border-bottom: 1px solid ${({ theme }) => theme.color.silver};
    width: 100%;
    padding-bottom: ${vwMobile(3)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(1.8)};
      padding-bottom: ${vwTablet(3)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(12)};
      letter-spacing: ${vw(2)};
      padding-bottom: ${vw(7)};
    }
  }
  .content {
    height: 79vh;
    padding: ${vwMobile(15)};
    overflow: scroll;
    @media (max-width: ${media.xs}) and (min-height: 750px) {
      height: 82vh;
    }
    @media (min-width: ${media.xsup}) {
      padding: ${vwTablet(15)};
      height: 85vh;
    }
    @media (min-width: ${media.mdup}) {
      padding: 0;
      height: auto;
      width: ${vw(502)};
    }
  }
  p {
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(0.8)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.darkBlue};
    opacity: 0.75;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(0.8)};
    }
    @media (min-width: ${media.xsup}) {
      font-size: ${vw(12)};
      letter-spacing: ${vw(2)};
    }
  }
  h4 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vwMobile(13)};
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: ${vwMobile(0.4)};
    color: ${({ theme }) => theme.color.text};
    &.total {
      color: ${({ theme }) => theme.color.earth};
    }
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(0.4)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(20)};
      letter-spacing: ${vw(1.1)};
      line-height: 1.5;
      color: ${({ theme }) => theme.color.darkBlue};
    }
  }
  .plan-image {
    width: 100%;
    height: auto;
    margin-top: ${vwMobile(30)};
    @media (min-width: ${media.xsup}) {
      margin-top: ${vwTablet(30)};
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: ${media.mdup}) {
      display: none;
      width: 100%;
    }
  }
  .plate-image {
    height: auto;
    max-height: ${vwMobile(50)};
    @media (min-width: ${media.xsup}) {
      max-height: ${vwTablet(100)};
    }
    @media (min-width: ${media.mdup}) {
      max-height: ${vw(100)};
      margin-top: ${vw(140)};
    }
  }
  .north {
    margin: ${vwMobile(5)} 0;
    margin-left: ${vwMobile(243)};
    @media (min-width: ${media.xsup}) {
      margin: ${vwTablet(5)} 0;
    }
    @media (min-width: ${media.mdup}) {
      display: none;
    }
  }
  .desktop-plan {
    display: none;
    @media (min-width: ${media.mdup}) {
      display: flex;
      align-items: center;
      margin-left: ${vw(120)};
      position: relative;
      width: ${vw(620)};
      height: ${vw(668)};
      .plan {
        max-width: ${vw(530)};
        max-height: ${vw(668)};
        object-fit: contain;
      }
      .compass {
        position: absolute;
        bottom: 0;
        right: 0;
        width: ${vw(61)};
        height: ${vw(38)};
      }
    }
  }
`;

const NextPlan = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.color.silver};
  margin: ${vwMobile(30)} 0 0;
  padding: ${vwMobile(15)} 0 0;
  @media (min-width: ${media.xsup}) {
    margin: ${vwTablet(30)} 0 0;
    padding: ${vwTablet(15)} 0 0;
  }
  @media (min-width: ${media.mdup}) {
    margin: ${vw(26)} 0 0;
    padding: ${vw(15)} 0 0;
  }
  h3 {
    border-bottom: 0;
    width: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  img {
    margin: 0;
    margin-left: ${vwMobile(15)};
    @media (min-width: ${media.xsup}) {
      margin-left: ${vwTablet(15)};
    }
  }
  .left img {
    margin-left: 0;
    margin-right: ${vwMobile(15)};
    @media (min-width: ${media.xsup}) {
      margin-right: ${vwTablet(15)};
    }
  }
`;

const Sizes = styled.div`
  display: flex;
  margin: ${vwMobile(10)} 0;
  @media (min-width: ${media.mdup}) {
    margin: ${vw(18)} 0 ${vw(30)};
  }
  div {
    margin-right: ${vwMobile(25)};
    @media (min-width: ${media.xsup}) {
      margin-right: ${vwTablet(25)};
    }
  }
`;
