export default [
  {
    title: 'Schools & Services',
    points: [
      {
        name: 'Kitsilano Secondary School',
        number: '1',
        top: 57,
        left: 29.5,
      },
      {
        name: 'St. John’s School',
        number: '2',
        top: 54,
        left: 40.5,
      },
      {
        name: 'St. Augustine’s Elementary',
        number: '3',
        top: 47.5,
        left: 43.2,
      },
      {
        name: 'Lord Tennyson Elementary',
        number: '4',
        top: 57,
        left: 49,
      },
      {
        name: 'Vancouver General Hospital',
        number: '5',
        top: 57,
        left: 81,
      },
      {
        name: 'Stanley Industrial Alliance Stage',
        number: '6',
        top: 58.5,
        left: 65.5,
      },
    ],
  },
  {
    title: 'Parks & Recreation',
    points: [
      {
        name: 'Kitsilano Community Centre & Kitsilano Rink',
        number: '7',
        top: 58.5,
        left: 33,
      },
      {
        name: 'Meraloma Rugby Club',
        number: '8',
        top: 55.5,
        left: 36.5,
      },
      {
        name: 'Vancouver Lawn Tennis & Badminton Club',
        number: '9',
        top: 68.5,
        left: 60,
      },
      {
        name: 'The Arbutus Greenway',
        number: '10',
        top: 45,
        left: 52,
      },
      {
        name: 'Kitsilano Beach',
        number: '11',
        top: 19.5,
        left: 45.5,
      },
      {
        name: 'Kitsilano Yacht Club',
        number: '12',
        top: 25,
        left: 34,
      },
      {
        name: 'The Arbutus Club',
        number: '13',
        top: 90,
        left: 45,
      },
      {
        name: 'Shaughnessy Park',
        number: '14',
        top: 74,
        left: 68,
      },
      {
        name: 'Steve Nash Fitness World',
        number: '15',
        top: 53,
        left: 43.2,
      },
    ],
  },
  {
    title: 'Markets',
    points: [
      {
        name: 'Loblaws City Market',
        number: '16',
        top: 69.5,
        left: 43.4,
      },
      {
        name: 'Meinhardt Fine Foods',
        number: '17',
        top: 66,
        left: 65,
      },
      {
        name: 'Greens Organic and Natural Market',
        number: '18',
        top: 53,
        left: 48.5,
      },
      {
        name: 'Marketplace IGA',
        number: '19',
        top: 53,
        left: 38,
      },
    ],
  },
  {
    title: 'Restaurants and Cafes',
    points: [
      {
        name: 'Lunch Club',
        number: '20',
        top: 68,
        left: 44.8,
      },
      {
        name: 'Tangram Creamery',
        number: '21',
        top: 62,
        left: 43,
      },
      {
        name: 'Plaisir Sucré',
        number: '22',
        top: 57.5,
        left: 44.8,
      },
      {
        name: 'Trees Organic Coffee',
        number: '23',
        top: 57,
        left: 43,
      },
      {
        name: 'Heirloom Vegetarian Restaurant',
        number: '24',
        top: 59.5,
        left: 63,
      },
      {
        name: 'West Restaurant',
        number: '25',
        top: 63,
        left: 63,
      },
      {
        name: 'The Stable House Bistro',
        number: '26',
        top: 65,
        left: 61.5,
      },
      {
        name: 'Thomas Hass',
        number: '27',
        top: 52,
        left: 30,
      },
      {
        name: 'Rain or Shine Ice Cream',
        number: '28',
        top: 39.5,
        left: 50,
      },
      {
        name: 'TurF',
        number: '29',
        top: 38,
        left: 47,
      },
      {
        name: 'Sophie’s Cosmic Cafe',
        number: '30',
        top: 38,
        left: 45,
      },
      {
        name: 'Nook Restaurant',
        number: '31',
        top: 30,
        left: 42,
      },
      {
        name: 'The Boathouse',
        number: '32',
        top: 24,
        left: 43.5,
      },
    ],
  },
  {
    title: 'Shops and Galleries',
    points: [
      {
        name: 'Arc’teryx Kitsilano',
        number: '33',
        top: 38,
        left: 48,
      },
      {
        name: 'Lululemon',
        number: '34',
        top: 38,
        left: 43,
      },
      {
        name: 'Kit and Ace',
        number: '35',
        top: 38,
        left: 39.5,
      },
      {
        name: 'Pottery Barn',
        number: '36',
        top: 57,
        left: 65.5,
      },
      {
        name: 'Anthropologie',
        number: '37',
        top: 65,
        left: 65.5,
      },
      {
        name: 'Bau-Xi Gallery',
        number: '38',
        top: 68,
        left: 63.5,
      },
    ],
  },
];
