import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Button from 'components/Button';

const Footer = () => {
  return (
    <Root>
      <div className="top-row">
        <Logo>
          <img
            src={require('src/assets/images/logos/arbutus-logo.svg')}
            alt="The Arbutus"
          />
          <Button text="Register Now" to="/register" />
        </Logo>
        <Info>
          <h2>Presentation Centre</h2>
          <a
            href="https://www.google.com/maps/place/2090+Alma+St,+Vancouver,+BC+V6R+3P9/@49.2677713,-123.1879283,17z/data=!3m1!4b1!4m5!3m4!1s0x5486725606f3942d:0x2662b0b2f4950d72!8m2!3d49.2677678!4d-123.1857343"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>2090 Alma St, Vancouver, BC</p>
          </a>
          <a href="tel:604-307-0607">604-307-0607</a>
          <a href="mailto:info@thearbutus.com">info@thearbutus.com</a>
        </Info>
        <Map>
          <h2>Project Location</h2>
          <a
            href="https://www.google.com/maps/place/2880+Arbutus+St,+Vancouver,+BC+V6J+3Y7/@49.2602629,-123.1548707,17z/data=!3m1!4b1!4m5!3m4!1s0x548673ba9300e97b:0x4435aae65ea43203!8m2!3d49.2602594!4d-123.1526767"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('src/assets/images/footer/footer-map.png')}
              alt="Map"
            />
          </a>
        </Map>
      </div>
      <BottomRow>
        <a
          href="http://originproperties.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require('src/assets/images/logos/origin-properties.svg')}
            alt="Origin Properties"
          />
        </a>
        <div>
          <p>
            <span>Origin Properties, {new Date().getFullYear()}</span>
          </p>
          <span className="line">|</span>
          <Link to="/terms">Terms</Link> <span>|</span>{' '}
          <Link to="/privacy">Privacy</Link>
        </div>
      </BottomRow>
      <Disclaimer>
        This is not an offer for sale and is for information purposes only. An
        offer for sale may only be made in conjunction with a Disclosure
        Statement. E. &amp; O.E. The above images or drawings are for
        illustration purposes only. The developer reserves the right to alter
        size, price, design and/or finishings at any time.
      </Disclaimer>
      <Leaf
        src={require('src/assets/images/footer/leaf-footer.png')}
        alt="Leaf"
      />
    </Root>
  );
};

export default Footer;

const Root = styled.div`
  position: relative;
  text-align: center;
  padding: ${vwMobile(35)} ${vwMobile(10)} ${vwMobile(60)};
  @media (min-width: ${media.xsup}) {
    text-align: left;
    padding: ${vwTablet(35)} ${vwTablet(24)} ${vwTablet(80)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(75)} ${vw(106)} ${vw(60)};
  }
  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.text};
    opacity: 0.9;
    margin-bottom: ${vwMobile(10)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(10)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(10)};
    }
  }
  .top-row {
    @media (min-width: ${media.xsup}) {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const Leaf = styled.img`
  position: absolute;
  right: 0;
  bottom: ${vwMobile(70)};
  z-index: -1;
  width: ${vwMobile(100)};
  @media (min-width: ${media.xsup}) {
    bottom: ${vwTablet(70)};
    width: ${vwTablet(100)};
  }
  @media (min-width: ${media.mdup}) {
    bottom: ${vw(70)};
    width: ${vw(100)};
  }
`;

const Logo = styled.div`
  @media (min-width: ${media.xsup}) {
    order: 2;
    text-align: center;
  }
  a {
    margin: ${vwMobile(20)} auto 0;
    @media (min-width: ${media.xsup}) {
      margin: ${vwTablet(20)} auto 0;
    }
    @media (min-width: ${media.mdup}) {
      margin: ${vw(20)} auto 0;
    }
  }
`;

const BottomRow = styled.div`
  @media (min-width: ${media.xsup}) {
    display: flex;
    align-items: flex-end;
  }
  div {
    margin: ${vwMobile(40)} 0 ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      margin: 0;
      display: flex;
      margin-left: ${vwTablet(80)};
    }
    @media (min-width: ${media.mdup}) {
      margin-left: ${vw(300)};
    }
  }
  div,
  p,
  a {
    font-size: ${vwMobile(16)};
    line-height: 1.75;
    font-weight: 300;
    letter-spacing: ${vwMobile(0.8)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(0.8)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(0.8)};
    }
  }
  img {
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(142)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(142)};
    }
  }
  a {
    text-decoration: underline;
  }
  span {
    color: ${({ theme }) => theme.color.textLightBlue};
    @media (min-width: ${media.xsup}) {
      margin: 0 ${vwTablet(3)};
    }
    @media (min-width: ${media.mdup}) {
      margin: 0 ${vw(3)};
    }
  }
  .line {
    display: none;
    @media (min-width: ${media.xsup}) {
      display: block;
    }
  }
`;

const Info = styled.div`
  margin: ${vwMobile(55)} 0;
  @media (min-width: ${media.xsup}) {
    margin: 0;
    order: 1;
  }
  p,
  a {
    display: block;
    font-family: ${({ theme }) => theme.fonts.main};
    font-weight: 300;
    font-size: ${vwMobile(16)};
    line-height: 1.75;
    letter-spacing: ${vwMobile(0.8)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(0.8)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(0.8)};
    }
  }
`;

const Map = styled.div`
  @media (min-width: ${media.xsup}) {
    order: 3;
    text-align: right;
  }
  img {
    width: ${vwMobile(237)};
    margin-bottom: ${vwMobile(40)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(237)};
      margin-bottom: 0;
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(281)};
    }
  }
`;

const Disclaimer = styled.p`
  font-size: ${vwMobile(12)};
  font-weight: 300;
  letter-spacing: ${vwMobile(0.6)};
  line-height: 1.67;
  color: ${({ theme }) => theme.color.textLightBlue};
  max-width: ${vwMobile(273)};
  margin: 0 auto;
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(12)};
    letter-spacing: ${vwTablet(0.6)};
    max-width: none;
    text-align: center;
    margin-top: ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(12)};
    letter-spacing: ${vw(0.6)};
    margin-top: ${vw(20)};
  }
`;
