import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SeasonLogo = ({ active, path }) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      active={active}
    >
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle
          cx="14.5"
          cy="14.5"
          r="14.5"
          // stroke={active ? '#1A373C' : '#FFF'}
        />
        <g className="path">{path}</g>
      </g>
    </SVG>
  );
};

SeasonLogo.propTypes = {
  active: PropTypes.bool,
  path: PropTypes.object,
};

export default SeasonLogo;

const SVG = styled.svg`
  circle {
    stroke: ${props => (props.active ? '#1A373C' : '#FFF')};
    transition: 0.3s ease;
  }
  .path {
    fill: ${props => (props.active ? '#1A373C' : '#FFF')};
    transition: 0.3s ease;
  }
`;
