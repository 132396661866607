import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const Button = props => {
  return (
    <Root
      {...props}
      as={props.to ? Link : 'button'}
      inverted={props.inverted ? props.inverted.toString() : 'false'}
    >
      <div className="inner">{props.text}</div>
    </Root>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  inverted: PropTypes.bool,
};

export default Button;

const Root = styled.button`
  padding: ${vwMobile(8)};
  background-color: ${props =>
    props.inverted === 'true' ? 'white' : props.theme.color.darkBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${vwMobile(180)};
  transition: 0.3s ease;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(8)};
    max-width: ${vwTablet(180)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(8)};
    max-width: ${vw(180)};
    &:hover {
      background-color: ${props =>
        props.inverted === 'true' ? props.theme.color.darkBlue : 'white'};
      border: ${props =>
        props.inverted === 'true'
          ? 'none'
          : `1px solid ${props.theme.color.darkBlue}`};
      .inner {
        color: ${props =>
          props.inverted === 'true' ? 'white' : props.theme.color.darkBlue};
        border-color: ${props =>
          props.inverted === 'true' ? 'white' : props.theme.color.darkBlue};
      }
    }
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${vwMobile(164)};
    height: ${vwMobile(32)};
    border: 1px solid
      ${props =>
        props.inverted === 'true' ? props.theme.color.darkBlue : 'white'};
    line-height: 1;
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vwMobile(14)};
    font-weight: normal;
    letter-spacing: ${vwMobile(0.5)};
    text-transform: uppercase;
    color: ${props =>
      props.inverted === 'true' ? props.theme.color.darkBlue : 'white'};
    text-align: center;
    transition: 0.3s ease;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(164)};
      height: ${vwTablet(32)};
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.5)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(164)};
      height: ${vw(32)};
      font-size: ${vw(14)};
      letter-spacing: ${vw(0.5)};
    }
  }
`;
