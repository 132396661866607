import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import Button from '../elements/Button';

const Header = ({ activeType, handleType }) => {
  return (
    <Root>
      <h1>Arbutus Floor Plans</h1>
      <div className="desktop-header">
        <p>Display Plans:</p>
        <div className="buttons">
          <StyledButton
            text="By Level"
            active={activeType === 0}
            handleClick={() => handleType(0)}
          />
          <StyledButton
            text="By Type"
            active={activeType === 1}
            handleClick={() => handleType(1)}
          />
        </div>
      </div>
    </Root>
  );
};

Header.propTypes = {
  activeType: PropTypes.number,
  handleType: PropTypes.func,
};

export default Header;

const Root = styled.div`
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.silver};
  padding-bottom: ${vwMobile(20)};
  @media (min-width: ${media.xsup}) {
    padding-bottom: ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    padding-bottom: ${vw(17)};
    display: flex;
    justify-content: space-between;
  }
  p {
    font-size: ${vwMobile(10)};
    font-weight: bold;
    letter-spacing: ${vwMobile(1.8)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.blueGray};
    margin: ${vwMobile(15)} 0;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(1.8)};
      margin: ${vwTablet(15)} 0;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(10)};
      letter-spacing: ${vw(1.8)};
      margin: 0 ${vw(34)};
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
  }
  .desktop-header {
    @media (min-width: ${media.mdup}) {
      display: flex;
    }
  }
`;

const StyledButton = styled(Button)`
  width: ${vwMobile(120)};
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(120)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(120)};
  }
`;
