import React from 'react';
import styled from 'styled-components';
import { vwMobile, vw } from 'src/styles/utils';
import media from 'src/styles/media';
import Footer from 'components/Footer';

const Page = props => {
  return (
    <>
      <Root {...props}>{props.children}</Root>
      <Footer />
    </>
  );
};

export default Page;

const Root = styled.div`
  padding: ${vwMobile(10)};
  @media (min-width: ${media.mdup}) {
    padding: ${vw(90)} ${vw(28)} ${vw(15)};
  }
`;
