import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Parallax } from 'react-scroll-parallax';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import ParallaxImage from 'components/ParallaxImage';

const Content = () => {
  const settings = {
    dots: true,
    infinite: true,
  };
  return (
    <Root>
      <img
        src={require('src/assets/images/icons/right-leaf.svg')}
        alt="Leaf"
        className="leaf"
      />
      <Text>
        <h1>Quintessential Kitsilano</h1>
        <p>
          Kitsilano blends the convenience of urban with a relaxed suburban feel
          to create a lifestyle where going downtown is just as effortless as
          meeting up on West 4th for coffee.
        </p>
      </Text>
      <StyledSlider {...settings}>
        <img
          src={require('src/assets/images/lifestyle/arbutus-115-min.jpg')}
          alt=""
        />
        <img
          src={require('src/assets/images/lifestyle/arbutus-207-min.jpg')}
          alt=""
        />
        <img
          src={require('src/assets/images/lifestyle/arbutus-269-min.jpg')}
          alt=""
        />
      </StyledSlider>
      <Desktop>
        <Parallax y={[-8, 8]}>
          <StyledParallaxImage
            src={require('src/assets/images/lifestyle/arbutus-269-min.jpg')}
            alt=""
          />
        </Parallax>
        <Parallax y={[8, -8]}>
          <StyledParallaxImage
            src={require('src/assets/images/lifestyle/arbutus-207-min.jpg')}
            alt=""
          />
        </Parallax>
        <Parallax y={[-8, 8]}>
          <StyledParallaxImage
            src={require('src/assets/images/lifestyle/arbutus-115-min.jpg')}
            alt=""
          />
        </Parallax>
      </Desktop>
      <Connected>
        <img
          src={require('src/assets/images/icons/left-leaf.svg')}
          alt="Leaf"
          className="small-leaf"
        />
        <h1>Naturally Connected</h1>
        <p>
          Walkable, bike-friendly, filled with shopping, dining and easy access
          to transit—Kitsilano is dynamic, vibrant, a family-friendly place to
          call home, and undeniably one of the most desirable neighbourhoods in
          Canada.
        </p>
        <div className="distance">
          <div>
            <h2>5</h2>
            <h3>Min Walk</h3>
            <p>To Arbutus Park</p>
            <p>To Connaught Park</p>
          </div>
          <div>
            <h2>8</h2>
            <h3>Min By Bike</h3>
            <p>To Kits Beach</p>
            <p>To West 4th</p>
          </div>
          <div>
            <h2>15</h2>
            <h3>Min By Car</h3>
            <p>To UBC</p>
            <p>To Downtown Vancouver</p>
          </div>
          <div>
            <Modo
              src={require('src/assets/images/lifestyle/modo-logo.png')}
              alt="Modo Carshare"
            />
            <p>Free Membership</p>
            <p>To All Owners</p>
          </div>
        </div>
      </Connected>
    </Root>
  );
};

export default Content;

const Root = styled.div`
  margin-top: ${vwMobile(60)};
  position: relative;
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(60)};
  }
  .leaf {
    position: absolute;
    right: ${vw(-28)};
    top: ${vw(-200)};
    z-index: 2;
    width: ${vw(115)};
    height: ${vw(394)};
    display: none;
    @media (min-width: ${media.mdup}) {
      display: block;
    }
  }
`;

const Text = styled.div`
  background-color: #b9d5d2;
  background-image: url(${require('src/assets/images/lifestyle/leaf-bg.svg')});
  background-position: top left;
  background-size: 50%;
  background-repeat: no-repeat;
  text-align: center;
  padding: ${vwMobile(63)} ${vwMobile(21)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(63)} ${vwTablet(21)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(620)};
    padding: ${vw(126)} 0;
  }
  p {
    @media (min-width: ${media.xsup}) {
      max-width: ${vwTablet(384)};
      margin: 0 auto;
    }
    @media (min-width: ${media.mdup}) {
      max-width: ${vw(473)};
      margin: 0 auto;
    }
  }
  h1 {
    margin-bottom: ${vwMobile(24)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(24)};
    }
    @media (min-width: ${media.xsup}) {
      max-width: ${vw(350)};
      margin: 0 auto ${vw(20)};
    }
  }
`;

const Desktop = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: flex;
    justify-content: space-between;
    margin-top: ${vw(-200)};
    padding: 0 ${vw(216)};
  }
`;

const StyledParallaxImage = styled(ParallaxImage)`
  width: ${vw(285)};
  height: ${vw(400)};
  object-fit: cover;
`;

const Connected = styled.div`
  text-align: center;
  padding: ${vwMobile(50)} 0 ${vwMobile(60)};
  color: ${({ theme }) => theme.color.darkBlue};
  position: relative;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(50)} 0 ${vwTablet(60)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(80)} 0 ${vw(70)};
  }
  .small-leaf {
    display: none;
    position: absolute;
    left: ${vw(-28)};
    top: ${vw(-50)};
    width: ${vw(109)};
    height: ${vw(360)};
    @media (min-width: ${media.mdup}) {
      display: block;
    }
  }
  h1 {
    margin-bottom: ${vwMobile(24)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(24)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(20)};
    }
  }
  p {
    max-width: ${vwMobile(281)};
    margin: 0 auto;
    @media (min-width: ${media.xsup}) {
      max-width: ${vwTablet(384)};
    }
    @media (min-width: ${media.mdup}) {
      max-width: ${vw(553)};
    }
  }
  h2 {
    margin-top: ${vwMobile(40)};
    font-size: ${vwMobile(30)};
    font-weight: normal;
    letter-spacing: ${vwMobile(0.9)};
    @media (min-width: ${media.xsup}) {
      margin-top: ${vwTablet(40)};
      font-size: ${vwTablet(30)};
      letter-spacing: ${vwTablet(0.9)};
    }
    @media (min-width: ${media.mdup}) {
      margin-top: 0;
      font-size: ${vw(30)};
      letter-spacing: ${vw(0.9)};
    }
  }
  h3 {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(12)};
    font-weight: normal;
    letter-spacing: ${vwMobile(2)};
    margin-bottom: ${vwMobile(17)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(17)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(12)};
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(17)};
    }
  }
  .distance {
    @media (min-width: ${media.mdup}) {
      display: flex;
      justify-content: space-between;
      padding: 0 ${vw(313)};
      margin-top: ${vw(65)};
    }
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  height: ${vwMobile(310)};
  margin-top: ${vwMobile(10)};
  overflow: hidden;
  @media (min-width: ${media.mdup}) {
    display: none;
  }
  img {
    width: 100%;
    height: ${vwMobile(310)};
    object-fit: cover;
  }
  .slick-dots {
    bottom: 0;
    button {
      width: ${vwMobile(8)};
      height: ${vwMobile(8)};
      border: 1px solid white;
      border-radius: 50%;
      position: relative;
      @media (min-width: ${media.xsup}) {
        width: ${vwTablet(8)};
        height: ${vwTablet(8)};
      }
      &::before {
        content: '';
      }
    }
    .slick-active button::before {
      content: '';
      width: ${vwMobile(4)};
      height: ${vwMobile(4)};
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (min-width: ${media.xsup}) {
        width: ${vwTablet(4)};
        height: ${vwTablet(4)};
      }
    }
  }
`;

const Modo = styled.img`
  width: ${vwMobile(64)};
  margin-bottom: ${vwMobile(17)};
  margin-top: ${vwMobile(40)};
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(64)};
    margin-bottom: ${vwTablet(17)};
    margin-top: ${vwTablet(40)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(64)};
    margin-bottom: ${vw(17)};
    margin-top: ${vw(-8.5)};
  }
`;
