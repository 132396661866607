import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

import ParallaxImage from 'components/ParallaxImage';

import media from 'src/styles/media';
import { vwMobile, vw, vwTablet } from 'src/styles/utils';

const ContentItem = props => {
  const { item } = props;
  return (
    <Root>
      <Parallax y={[5, -5]}>
        <ParallaxImage
          src={item.image}
          alt={item.title}
          className="main-image"
        />
      </Parallax>
      <Parallax y={[-5, 5]}>
        <Card to={item.to}>
          <div>
            <h2>{item.page}</h2>
            <h1>{item.title}</h1>
            <img
              src={require('src/assets/images/icons/plant-icon-mobile.svg')}
              alt="leaf"
            />
          </div>
        </Card>
      </Parallax>
      <p>{item.text}</p>
      <Link to={item.to} className="text-link">
        <img
          src={require('src/assets/images/icons/link-icon-m.svg')}
          alt={item.linkText}
        />
        {item.linkText}
      </Link>
    </Root>
  );
};

ContentItem.propTypes = {
  item: PropTypes.object,
};

export default ContentItem;

const arrow = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
  transform: translateX(5px);
  }
`;

const Card = styled(Link)`
  display: block;
  width: ${vwMobile(239)};
  height: ${vwMobile(208)};
  background-color: rgba(247, 247, 247, 0.9);
  text-align: center;
  text-transform: uppercase;
  padding: ${vwMobile(7)};
  margin: ${vwMobile(-104)} auto ${vwMobile(20)};
  position: relative;
  z-index: 2;
  transition: 0.3s ease;
  cursor: pointer;
  @media (min-width: ${media.mdup}) {
    width: ${vw(441)};
    height: ${vw(382)};
    margin: 0;
    padding: ${vw(14)};
    &:hover {
      background-color: rgba(247, 247, 247, 1);
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(11)};
    font-weight: 300;
    line-height: 3.09;
    letter-spacing: ${vwMobile(1.7)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(13)};
      letter-spacing: ${vw(2)};
      line-height: normal;
    }
  }
  h1 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vwMobile(18)};
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: ${vwMobile(1)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(24)};
      letter-spacing: ${vw(2.4)};
      line-height: 1.42;
    }
  }
  img {
    width: ${vwMobile(50)};
    height: ${vwMobile(30)};
    object-fit: contain;
    margin: 0 auto;
    @media (min-width: ${media.mdup}) {
      width: ${vw(71)};
      height: ${vw(28)};
    }
  }
  div {
    border: 1px solid ${({ theme }) => theme.color.darkBlue};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: ${vwMobile(20)} ${vwMobile(5)};
    @media (min-width: ${media.mdup}) {
      padding: ${vw(40)} ${vw(25)};
    }
  }
`;

const Root = styled.div`
  position: relative;
  .main-image {
    width: 100%;
    height: ${vwMobile(310)};
    object-fit: cover;
    object-position: center;
    position: relative;
    z-index: 1;
    @media (min-width: ${media.mdup}) {
      height: ${vw(600)};
      width: auto;
    }
  }
  p {
    font-family: ${({ theme }) => theme.fonts.main};
    font-weight: 300;
    font-size: ${vwMobile(14)};
    line-height: 1.79;
    letter-spacing: ${vwMobile(0.7)};
    max-width: ${vwMobile(239)};
    margin: 0 auto;
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(0.8)};
      line-height: 1.75;
      max-width: ${vw(565)};
      margin: 0;
    }
  }
  .text-link {
    display: block;
    max-width: ${vwMobile(239)};
    margin: ${vwMobile(15)} auto ${vwMobile(50)};
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(14)};
    line-height: 2;
    letter-spacing: ${vwMobile(0.4)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(0.5)};
      line-height: 1.75;
      margin: 0;
      &:hover img {
        animation: ${arrow} 1.5s ease-out infinite;
      }
    }
    img {
      width: ${vwMobile(6)};
      height: ${vwMobile(10)};
      margin-right: ${vwMobile(10)};
      @media (min-width: ${media.mdup}) {
        width: ${vw(6)};
        height: ${vw(10)};
        margin-right: ${vw(10)};
      }
    }
  }
  &:nth-of-type(2n) {
    &::after {
        content: '';
        width: ${vwMobile(91)};
        height: ${vwMobile(363)};
        background-image: url(${require('src/assets/images/home/plant-mobile.svg')});
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        right: ${vwMobile(-10)};
        top: ${vwMobile(-100)};
        z-index: -1;
        @media (min-width: ${media.xsup}) {
          right: ${vwTablet(-24)};
          top: ${vwTablet(-100)};
          width: ${vwTablet(91)};
          height: ${vwTablet(363)};
        }
        @media (min-width: ${media.mdup}) {
          right: ${vw(-28)};
          top: ${vw(-200)};
          width: ${vw(117)};
          height: ${vw(400)};
          background-image: url(${require('src/assets/images/home/plant-background.svg')});
          z-index: 1;
        }
      }
  }
  @media (min-width: ${media.mdup}) {
    &:first-of-type {
      display: flex;
      flex-wrap: wrap;
      .main-image {
        width: ${vw(1200)};
      }
      ${Card} {
        position: relative;
        margin-left: ${vw(-290)};
        margin-top: ${vw(109)};
      }
      p {
        margin: ${vw(52)} ${vw(257)} ${vw(10)};
      }
      .text-link {
        margin: 0 ${vw(257)};
      }
    }
    &:nth-of-type(2n) {
      background-color: #b9d5d2;
      margin-top: ${vw(182)};
      height: ${vw(619)};
      margin-bottom: ${vw(195)};
      display: flex;
      flex-wrap: wrap;
      position: relative;
      ${Card} {
        margin-left: ${vw(678)};
        margin-top: ${vw(-116)};
      }
      /* &::after {
        content: '';
        width: ${vwMobile(91)};
        height: ${vwMobile(363)};
        background-image: url(${require('src/assets/images/home/plant-mobile.svg')});
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        right: ${vwMobile(-10)};
        top: ${vwMobile(-200)};
        @media (min-width: ${media.xsup}) {
          right: ${vwTablet(-24)};
          top: ${vwTablet(-200)};
          top: 0;
          width: ${vwTablet(91)};
          height: ${vwTablet(363)};
        }
        @media (min-width: ${media.mdup}) {
          right: ${vw(-28)};
          top: ${vw(-200)};
          width: ${vw(117)};
          height: ${vw(400)};
          background-image: url(${require('src/assets/images/home/plant-background.svg')});
        }
      } */
      .parallax-outer:first-of-type {
        order: 2;
      }
      .parallax-outer:last-of-type {
        order: 1;
      }
      .main-image {
        width: ${vw(446)};
        height: ${vw(605)};
        margin-left: ${vw(117)};
        margin-top: ${vw(-120)};
      }
      p {
        margin-left: ${vw(121)};
        margin-top: ${vw(100)};
        order: 3;
      }
      .text-link {
        margin-left: ${vw(684)};
        margin-top: ${vw(-315)};
        order: 4;
      }
    }
    &:last-of-type {
      display: flex;
      flex-wrap: wrap;
      .main-image {
        width: ${vw(1121)};
        margin-left: ${vw(-190)};
      }
      ${Card} {
        margin-top: ${vw(106)};
      }
      .parallax-outer:first-of-type {
        order: 2;
      }
      .parallax-outer:last-of-type {
        order: 1;
      }
      p {
        max-width: ${vw(473)};
        margin: ${vw(71)} 0 ${vw(20)} ${vw(468)};
        order: 3;
      }
      .text-link {
        margin-left: ${vw(468)};
        margin-bottom: ${vw(70)};
        order: 4;
      }
    }
  }
`;
