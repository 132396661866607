import React, { Component } from 'react';
import styled from 'styled-components';
import { withController } from 'react-scroll-parallax';
import PropTypes from 'prop-types';

class ParallaxImage extends Component {
  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };

  render() {
    return (
      <Img src={this.props.src} onLoad={this.handleLoad} {...this.props} />
    );
  }
}

ParallaxImage.propTypes = {
  src: PropTypes.string,
};

export default withController(ParallaxImage);

const Img = styled.img``;
