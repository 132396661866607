import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import data from 'src/data/building';
import SeasonLogo from '../elements/SeasonLogo';

const marks = {
  20: 0,
  50: 1,
  80: 2,
};

class Seasons extends Component {
  state = {
    activeImage: 0,
    activeValue: 20,
  };

  handleSlider = index => {
    if (index >= 70) {
      this.setState({ activeImage: 2, activeValue: index });
    } else if (index >= 40) {
      this.setState({ activeImage: 1, activeValue: index });
    } else {
      this.setState({ activeImage: 0, activeValue: index });
    }
  };

  handleImage = index => {
    if (index > 3) {
      this.setState({
        activeImage: marks[index],
        activeValue: parseInt(Object.keys(marks)[index]),
      });
    } else {
      this.setState({
        activeImage: index,
        activeValue: parseInt(Object.keys(marks)[index]),
      });
    }
  };

  render() {
    // console.log(parseInt(Object.keys(marks)[this.state.activeImage]));
    return (
      <Root>
        <img
          src={require('src/assets/images/icons/right-leaf.svg')}
          alt="Leaf"
          className="leaf"
        />
        <h1 className="mobile">A home for all seasons</h1>
        <Images>
          <img
            src={require('src/assets/images/renderings/street-spring.jpg')}
            alt="Spring/Summer"
            className="static"
          />
          {data.seasons.map((item, index) => (
            <Image
              src={item.image}
              alt={item.title}
              active={this.state.activeImage === index}
              key={index}
            />
          ))}
        </Images>
        <Mobile activeImage={this.state.activeImage}>
          {data.seasons.map((item, index) => (
            <Icon
              key={index}
              onClick={() => this.handleImage(index)}
              active={this.state.activeImage === index}
            >
              <SeasonLogo
                path={item.logoPath}
                active={index === this.state.activeImage}
              />
              <h3>{item.title}</h3>
            </Icon>
          ))}
        </Mobile>
        <Desktop>
          <h1>A home for all seasons</h1>
          <Slider
            step={5}
            marks={marks}
            min={0}
            defaultValue={20}
            included={false}
            onChange={this.handleSlider}
            value={this.state.activeValue}
          />
          <div className="icons">
            {data.seasons.map((item, index) => (
              <Logo
                key={index}
                active={index === this.state.activeImage}
                onClick={() => this.handleImage(index)}
              >
                <SeasonLogo
                  active={index === this.state.activeImage}
                  path={item.logoPath}
                />
                <h3>{item.title}</h3>
              </Logo>
            ))}
          </div>
        </Desktop>
      </Root>
    );
  }
}

export default Seasons;

const Root = styled.div`
  margin-top: ${vwMobile(54)};
  position: relative;
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(54)};
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(65)};
  }
  h1 {
    text-align: center;
    margin-bottom: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(15)};
      &.mobile {
        display: none;
      }
    }
  }
  .leaf {
    position: absolute;
    z-index: 2;
    display: none;
    @media (min-width: ${media.mdup}) {
      right: ${vw(-28)};
      top: ${vw(-200)};
      display: block;
    }
  }
`;

const Icon = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(0.8)};
    text-align: center;
    text-transform: uppercase;
    transition: 0.3s ease;
    color: ${props => (props.active ? props.theme.color.darkBlue : 'white')};
    max-width: ${vwMobile(73)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      max-width: ${vwTablet(80)};
    }
  }
  img {
    width: ${vwMobile(29)};
    display: block;
    margin: ${vwMobile(9)} 0 ${vwMobile(5)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(50)};
      margin: ${vwTablet(15)} 0 ${vwTablet(10)};
    }
  }
`;

const Images = styled.div`
  width: 100%;
  height: ${vwMobile(310)};
  position: relative;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(691)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(691)};
  }
  .static {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.3s ease;
`;

const Desktop = styled.div`
  background-color: #b9d5d2;
  width: ${vw(855)};
  height: ${vw(148)};
  margin: ${vw(-58)} auto 0;
  padding: ${vw(4)} ${vw(65)};
  z-index: 2;
  position: relative;
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
  }
  .icons {
    display: flex;
    justify-content: space-around;
    padding: 0 ${vw(36)};
    width: 100%;
    margin-top: ${vw(15)};
    svg {
      width: ${vw(39)};
      height: ${vw(39)};
      margin: 0 auto;
      display: block;
    }
  }
  h1 {
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(20)};
    }
  }
  .rc-slider-track {
    background-color: transparent;
  }
  .rc-slider-handle {
    border-radius: 0;
    background-color: transparent;
    border: 0;
    background-image: url(${require('src/assets/images/icons/slider.png')});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: ${vw(16)};
    height: ${vw(25)};
    margin-left: ${vw(-9)};
    margin-top: ${vw(-12)};
  }
  .rc-slider-dot {
    display: none;
  }
  .rc-slider-mark-text {
    display: none;
  }
  .rc-slider-rail {
    background-color: rgba(255, 255, 255, 0.28);
    border-radius: ${vw(5)};
    height: ${vw(8)};
    position: relative;
    &::after {
      content: '';
      border-top: 1px dashed ${({ theme }) => theme.color.darkBlue};
      width: 98%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 1%;
      transform: translateY(-50%);
    }
  }
`;

const Logo = styled.div`
  width: 33%;
  cursor: pointer;
  h3 {
    font-size: ${vw(12)};
    letter-spacing: ${vw(2)};
    text-transform: uppercase;
    text-align: center;
    color: ${props => (props.active ? props.theme.color.darkBlue : 'white')};
    transition: 0.3s ease;
    margin-top: ${vw(5)};
  }
`;

const Mobile = styled.div`
  background-color: #b9d5d2;
  width: 100%;
  height: ${vwMobile(75)};
  display: flex;
  margin-top: ${vwMobile(8)};
  position: relative;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(125)};
    margin-top: ${vwTablet(10)};
  }
  @media (min-width: ${media.mdup}) {
    display: none;
  }
  &::after {
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.color.darkBlue};
    width: 33.33%;
    height: 3px;
    position: absolute;
    transition: 0.3s ease;
    bottom: 0;
    left: ${props => {
      if (props.activeImage === 0) {
        return 0;
      } else if (props.activeImage === 1) {
        return '33.33%';
      } else {
        return '66.66%';
      }
    }};
  }
  svg {
    width: ${vwMobile(29)};
    height: ${vwMobile(29)};
    display: block;
    margin: ${vwMobile(9)} 0 ${vwMobile(5)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(50)};
      height: ${vwTablet(50)};
      margin: ${vwTablet(15)} 0 ${vwTablet(10)};
    }
  }
`;
