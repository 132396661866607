import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import Input from '../elements/Input';
import Select from '../elements/Select';
import Radio from '../elements/Radio';
import Checkbox from '../elements/Checkbox';
import Button from '../elements/Button';

import enquiryData from 'src/data/form';

class Form extends Component {
  componentDidMount() {
   
    const scriptURL = 'https://script.google.com/macros/s/AKfycbyxbYuEOLd3lzyUfeazbU_zIv3AU12TqQ27OtmKUs_JisS93yM/exec'
    const form = document.forms['submit-to-google-sheet']

    form.addEventListener('submit', e => {
      e.preventDefault()
      fetch(scriptURL, { method: 'POST', body: new FormData(form)})
        .then(response => {
          console.log('Success!', response)
          window.location.href = "/thank-you/"
        })
        .catch(error => console.error('Error!', error.message))
    })
   
    // try {
    //   window.LassoCRM.tracker.patchRegistrationForms();
    // } catch (e) {
    //   console.log(e);
    // }
  }

  render() {
    return (
      <Root
        name="submit-to-google-sheet"
      >
        
        <FormBody>
          <UserDetails>
            <h2>Your Details</h2>
            <Input
              label="First Name"
              htmlId="firstName"
              required
              name="FirstName"
              type="text"
            />
            <Input
              label="Last Name"
              htmlId="lastName"
              required
              name="LastName"
              type="text"
            />
            <Input
              label="Email"
              htmlId="email"
              required
              name="Email"
              type="email"
            />
            <Input
              label="Phone Number"
              htmlId="phoneNumber"
              required
              name="Phone"
              type="tel"
              pattern="[0-9]{3}?(-|.|'')?[0-9]{3}?(-|.|'')?[0-9]{4}"
            />
            <Input
              label="Postal Code"
              htmlId="postalCode"
              required
              type="text"
              name="PostalCode"
              width={50}
              size={10}
              maxlength={10}
              pattern="[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d"
            />
          </UserDetails>

          <UserEnquiry>
            <h2>Your Enquiry</h2>

            <Radio
              options={enquiryData.realtor.options}
              question={enquiryData.realtor.question}
              name="Realtor"
            />
            <Radio
              options={enquiryData.workingWithRealtor.options}
              question={enquiryData.workingWithRealtor.question}
              name="WorkingWithRealtor"
            />

            <Select
              options={enquiryData.langPreference.options}
              label={enquiryData.langPreference.question}
              name="Language"
            />

            <Select
              options={enquiryData.howHeard.options}
              label={enquiryData.howHeard.question}
              name="LeadSource"
            />

            <Input
              label="Do you have any comments?"
              htmlId="comments"
              name="Comments"
              type="text"
            />

            <Checkbox
              text={enquiryData.promotional}
              name="AcceptPromotionEmail"
              value="Yes"
            />
          </UserEnquiry>
        </FormBody>

        <SubmissionWrapper>
          <p className="form-label">{enquiryData.terms}</p>
          <Button />
        </SubmissionWrapper>
      </Root>
    );
  }
}

export default Form;

const Root = styled.form`
  margin-bottom: ${vwMobile(50)};
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(50)};
  }
  @media (min-width: ${media.mdup}) {
    margin-bottom: ${vw(80)};
  }

  h2 {
    text-transform: uppercase;
    letter-spacing: ${vwMobile(0.44)};
    opacity: 0.9;
    line-height: normal;
    margin-bottom: ${vwMobile(22)};
    color: ${({ theme }) => theme.color.text};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(22)};
      letter-spacing: ${vwTablet(0.44)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(22)};
      letter-spacing: ${vw(0.44)};
    }
  }
`;

const UserDetails = styled.div`
  div:last-of-type input {
    margin-bottom: 0;
  }
  @media (min-width: ${media.mdup}) {
    width: 273px;
    margin-right: 148px;
  }
`;

const UserEnquiry = styled.div`
  margin-top: ${vwMobile(50)};
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(50)};
  }
  @media (min-width: ${media.mdup}) {
    width: 273px;
    margin-top: 0;
  }
`;

const SubmissionWrapper = styled.div`
  p:last-of-type {
    text-align: center;
    opacity: 0.75;
    margin-bottom: ${vwMobile(30)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(30)};
    }
  }
`;

const FormBody = styled.div`
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;
