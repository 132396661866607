import React from 'react';
import styled from 'styled-components';

import data from 'src/data/home';
import ContentItem from '../elements/ContentItem';

const Content = () => {
  return (
    <Root>
      {data.map((item, index) => (
        <ContentItem item={item} key={index} />
      ))}
    </Root>
  );
};

export default Content;

const Root = styled.div``;
