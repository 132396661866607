import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import Page from 'components/Page';
import Nextbar from 'components/Nextbar';
import Form from './sections/Form';

class Register extends Component {
  render() {
    return (
      <Page>
        <Container>
          <h1>Register Now</h1>
          <p>Make yourself at home in Kitsilano</p>
        </Container>
        <Form />
        <Nextbar to="/" />
      </Page>
    );
  }
}

export default Register;

const Container = styled.div`
  text-align: center;
  margin: ${vwMobile(46)} 0;
  @media (min-width: ${media.xsup}) {
    margin: ${vwTablet(46)} 0;
  }
  @media (min-width: ${media.mdup}) {
    margin: ${vw(112)} 0 ${vw(100)};
  }
  h1 {
    font-size: ${vwMobile(18)};
    line-height: 1.56;
    letter-spacing: ${vwMobile(1)};
    margin-bottom: ${vwMobile(20)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(1)};
      margin-bottom: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(24)};
      letter-spacing: ${vw(2.4)};
      line-height: 1.42;
      margin-bottom: ${vw(20)};
    }
  }
  p {
    font-size: ${vwMobile(14)};
    font-weight: 300;
    line-height: 1.79;
    letter-spacing: ${vwMobile(0.7)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.7)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(0.8)};
    }
  }
`;
