import React, { Component } from 'react';

import Page from 'components/Page';
import Hero from 'components/Hero';
import Content from './sections/Content';
import Map from './sections/Map';
import Nextbar from 'components/Nextbar';

class Lifestyle extends Component {
  render() {
    return (
      <Page>
        <Hero
          title="Urban Convenience &amp; Suburban Cool"
          subTitle="Lifestyle"
          image={require('src/assets/images/lifestyle/arbutus-292_edited-min.jpg')}
          header="Effortless lifestyle"
          text="Once of the best city beaches in the world and iconic views of the North Shore Mountains across English Bay—what makes Kitsilano famous is merely the beginning of an incredible place to live."
        />
        <Content />
        <Map />
        <Nextbar text="Next: Team" to="/team" />
      </Page>
    );
  }
}

export default Lifestyle;
