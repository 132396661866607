import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import Card from 'components/Card';
import mapstyles from 'src/data/mapstyles';

const Map = () => {
  const mapOptions = {
    styles: mapstyles,
    zoomControl: false,
    fullscreenControl: false,
  };
  return (
    <Root>
      <StyledCard title="2888 Arbutus St." subTitle="Project Location" />
      <Container>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBjffVYE4214dRYSEzO3bKSnqP3YAg8rZw' }}
          defaultCenter={{ lat: 49.2602594, lng: -123.1548654 }}
          defaultZoom={13.5}
          options={mapOptions}
        >
          <Logo
            src={require('src/assets/images/icons/contact-logo.png')}
            alt="Arbutus"
            lat={49.2601721}
            lng={-123.1548772}
          />
        </GoogleMapReact>
      </Container>
    </Root>
  );
};

export default Map;

const Root = styled.div`
  margin-top: ${vwMobile(180)};
  margin-bottom: ${vwMobile(40)};
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(80)};
    margin-bottom: ${vwTablet(40)};
  }
  @media (min-width: ${media.mdup}) {
    position: relative;
    margin-top: ${vw(60)};
    margin-bottom: ${vw(90)};
  }
`;

const Container = styled.div`
  width: 100%;
  height: ${vwMobile(308)};
  margin-top: ${vwMobile(-104)};
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(-104)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(1200)};
    height: ${vw(600)};
    margin-top: 0;
  }
`;

const StyledCard = styled(Card)`
  margin: 0 auto;
  @media (min-width: ${media.mdup}) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Logo = styled.img`
  height: ${vwMobile(33.2)};
  width: ${vwMobile(33.2)};
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(33.2)};
    width: ${vwTablet(33.2)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(49)};
    width: ${vw(49)};
  }
`;
