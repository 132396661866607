import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

//Custom radio from: https://www.w3schools.com/howto/howto_css_custom_checkbox.asp

const Radio = ({ question, options, name }) => (
  <Root>
    <Title className="form-label">{question}</Title>
    {options.map((option, index) => (
      <RadioButton key={index} className="container">
        <input
          type="radio"
          id={option.value}
          value={option.value}
          name={name}
          className="chekbox"
        />
        <span className="checkmark" />
        {option.title}
      </RadioButton>
    ))}
  </Root>
);

export default Radio;

const Root = styled.div`
  margin-bottom: ${vw(30)};
  /* Customize the label (the container) */
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-left: ${vwMobile(30)};
    padding-top: ${vwMobile(2)};
    margin-bottom: ${vwMobile(12)};
    opacity: 0.75;
    font-weight: 300;
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(14)};
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media (min-width: ${media.xsup}) {
      padding-left: ${vwTablet(30)};
      padding-top: ${vwTablet(2)};
      margin-bottom: ${vwTablet(12)};
      font-size: ${vwTablet(14)};
    }
    @media (min-width: ${media.mdup}) {
      padding-left: ${vw(30)};
      padding-top: ${vw(2)};
      margin-bottom: ${vw(12)};
      font-size: ${vw(14)};
    }
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${vwMobile(22)};
    width: ${vwMobile(22)};
    background-color: #f7f7f7;
    border: 1px solid ${({ theme }) => theme.colorBlack};
    border-radius: 50%;
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(22)};
      width: ${vwTablet(22)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(22)};
      width: ${vw(22)};
    }
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: ${vwMobile(3)};
    left: ${vwMobile(3)};
    width: ${vwMobile(14)};
    height: ${vwMobile(14)};
    border-radius: 50%;
    background: #b9d5d0;
    @media (min-width: ${media.xsup}) {
      top: ${vwTablet(3)};
      left: ${vwTablet(3)};
      width: ${vwTablet(14)};
      height: ${vwTablet(14)};
    }
    @media (min-width: ${media.mdup}) {
      top: ${vw(3)};
      left: ${vw(3)};
      width: ${vw(14)};
      height: ${vw(14)};
    }
  }
`;

const Title = styled.h3`
  margin-bottom: ${vwMobile(12)};
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: ${vwMobile(14)};
  font-weight: 300;
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(14)};
    margin-bottom: ${vwTablet(12)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(14)};
    margin-bottom: ${vw(12)};
  }
`;

const RadioButton = styled.label``;
