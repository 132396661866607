import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import Page from 'components/Page';
import Hero from 'components/Hero';
import Nextbar from 'components/Nextbar';

import data from 'src/data/team';

class Team extends Component {
  render() {
    return (
      <Page>
        <Hero
          title="A Foundation of Trust"
          subTitle="Team"
          image={require('src/assets/images/renderings/livingroom.jpg')}
          header=""
          text=""
        />
        <Content>
          <img
            src={require('src/assets/images/icons/right-leaf.svg')}
            alt="Leaf"
            className="leaf"
          />
          {data.map((item, index) => (
            <Item key={index}>
              <img src={item.logo} alt={item.title} className="logo" />
              <div className="info">
                <h1>{item.title}</h1>
                {item.description}
                <a
                  href={item.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('src/assets/images/icons/link-icon-m.svg')}
                    alt="Visit Website"
                  />
                  Visit Website
                </a>
              </div>
            </Item>
          ))}
        </Content>
        <Nextbar text="Next: Contact" to="/contact" />
      </Page>
    );
  }
}

export default Team;

const arrow = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
  transform: translateX(5px);
  }
`;

const Content = styled.div`
  padding: 0 ${vwMobile(10)};
  position: relative;
  @media (min-width: ${media.mdup}) {
    padding: ${vw(110)} ${vw(169)} ${vw(70)} ${vw(181)};
  }
  .leaf {
    display: none;
    @media (min-width: ${media.mdup}) {
      display: block;
      position: absolute;
      right: ${vw(-38)};
      top: 0;
    }
  }
`;

const Item = styled.div`
  @media (min-width: ${media.mdup}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h1 {
    margin-bottom: ${vwMobile(24)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(24)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(20)};
    }
  }
  p {
    margin-bottom: ${vwMobile(15)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(15)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(20)};
    }
  }
  a {
    display: block;
    margin-bottom: ${vwMobile(50)};
    font-size: ${vwMobile(14)};
    font-weight: normal;
    line-height: 2;
    letter-spacing: ${vwMobile(0.4)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(50)};
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.4)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(80)};
      font-size: ${vw(16)};
      line-height: 1.75;
      letter-spacing: ${vw(0.5)};
      &:hover img {
        animation: ${arrow} 1.5s ease-out infinite;
      }
    }
    img {
      padding-right: ${vwMobile(9)};
      @media (min-width: ${media.xsup}) {
        padding-right: ${vwTablet(9)};
      }
      @media (min-width: ${media.mdup}) {
        padding-right: ${vw(9)};
      }
    }
  }
  .logo {
    display: none;
    @media (min-width: ${media.mdup}) {
      display: block;
      max-height: ${vw(63)};
    }
  }
  .info {
    @media (min-width: ${media.mdup}) {
      max-width: ${vw(641)};
    }
  }
`;
