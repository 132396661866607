import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import Button from 'components/Button';

const Hero = () => {
  return (
    <Root>
      <h1>The Arbutus</h1>
      <h2>
        1 and 2 Bedrooms homes with rooftop decks on Vancouver’s West Side
      </h2>
      <StyledButton text="Register Now" inverted to="/register" />
    </Root>
  );
};

export default Hero;

const Root = styled.div`
  height: ${`calc(100vh - ${vwMobile(20)})`};
  background-image: url(${require('src/assets/images/renderings/street-spring.jpg')});
  background-size: cover;
  background-position: center;
  margin-bottom: ${vwMobile(10)};
  padding: ${vwMobile(37)} 0;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.header};
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  position: relative;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(40)} 0;
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(40)} 0;
  }
  h1 {
    font-size: ${vwMobile(25)};
    letter-spacing: ${vwMobile(0.8)};
    margin-bottom: ${vwMobile(13)};
    color: white;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(30)};
      letter-spacing: ${vwTablet(0.9)};
      margin-bottom: ${vwTablet(15)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(30)};
      letter-spacing: ${vw(0.9)};
      margin-bottom: ${vw(15)};
    }
  }
  h2 {
    font-size: ${vwMobile(13)};
    letter-spacing: ${vwMobile(0.4)};
    max-width: ${vwMobile(221)};
    margin: 0 auto;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(0.6)};
      max-width: ${vwTablet(400)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(18)};
      letter-spacing: ${vw(0.6)};
      max-width: ${vw(427)};
    }
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: ${vwMobile(50)};
  @media (min-width: ${media.xsup}) {
    bottom: ${vwTablet(50)};
  }
  @media (min-width: ${media.mdup}) {
    bottom: ${vw(65)};
  }
`;
