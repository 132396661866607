import React, { Component } from 'react';

import Page from 'components/Page';
import Hero from 'components/Hero';
import Seasons from './sections/Seasons';
import Content from './sections/Content';
import Features from './sections/Features';
import Nextbar from 'components/Nextbar';

class Building extends Component {
  render() {
    return (
      <Page>
        <Hero
          title="A Wonderfully Natural Perspective"
          subTitle="Building"
          image={require('src/assets/images/renderings/street-winter.jpg')}
          header="Natural and serene"
          text="There is a subtlety of light that warms and seduces, filtering through the trees, it takes us back to carefree days at the park and to long afternoons at the beach. Nostalgic and serene; it suspends time and allows us to leave the everyday and melt into its embrace. This is the architectural inspiration of The Arbutus."
        />
        <Seasons />
        <Content />
        <Features />
        <Nextbar text="Next: Lifestyle" to="/lifestyle" />
      </Page>
    );
  }
}

export default Building;
