import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { vwMobile } from 'src/styles/utils';

const Floorplate = ({ plate, handlePlan, floorplanData, isPlanActive }) => {
  return (
    <SVG
      width="795px"
      height="374px"
      viewBox="0 0 795 374"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        {plate.outline}
        {plate.units.map((unit, index) => (
          <G
            key={index}
            onClick={() =>
              handlePlan(
                floorplanData.indexOf(
                  floorplanData.filter(item => item.id === unit.id)[0]
                )
              )
            }
            active={isPlanActive(
              floorplanData.filter(item => item.id === unit.id)[0].bed
            )}
          >
            {unit.mockup}
          </G>
        ))}
      </g>
    </SVG>
  );
};

Floorplate.propTypes = {
  plate: PropTypes.object,
  handlePlan: PropTypes.func,
  floorplanData: PropTypes.array,
  isPlanActive: PropTypes.func,
};

export default Floorplate;

const SVG = styled.svg`
  width: 100%;
  height: auto;
  padding-left: ${vwMobile(5)};
`;

const G = styled.g`
  cursor: pointer;
  polygon {
    fill: ${props =>
      props.active ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0)'};
    transition: 0.3s ease;
  }
  text {
    opacity: ${props => (props.active ? 1 : 0.5)};
    transition: 0.3s ease;
  }
`;
