import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const Card = props => {
  const { title, subTitle } = props;
  return (
    <Root {...props}>
      <div>
        <h2>{subTitle}</h2>
        <h1>{title}</h1>
        <img
          src={require('src/assets/images/icons/plant-icon-mobile.svg')}
          alt="leaf"
        />
      </div>
    </Root>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default Card;

const Root = styled.div`
  display: block;
  width: ${vwMobile(239)};
  height: ${vwMobile(208)};
  background-color: rgba(247, 247, 247, 0.9);
  text-align: center;
  text-transform: uppercase;
  padding: ${vwMobile(7)};
  position: relative;
  z-index: 2;
  transition: 0.3s ease;
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(239)};
    height: ${vwTablet(208)};
    padding: ${vwTablet(7)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(441)};
    height: ${vw(382)};
    margin: 0;
    padding: ${vw(14)};
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(11)};
    font-weight: 300;
    line-height: 3.09;
    letter-spacing: ${vwMobile(1.7)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(1.7)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(13)};
      letter-spacing: ${vw(2)};
      line-height: normal;
    }
  }
  h1 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vwMobile(18)};
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: ${vwMobile(1)};
    color: ${({ theme }) => theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(1)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(24)};
      letter-spacing: ${vw(2.4)};
      line-height: 1.42;
    }
  }
  img {
    width: ${vwMobile(50)};
    height: ${vwMobile(30)};
    object-fit: contain;
    margin: 0 auto;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(50)};
      height: ${vwTablet(30)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(71)};
      height: ${vw(28)};
    }
  }
  div {
    border: 1px solid ${({ theme }) => theme.color.darkBlue};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: ${vwMobile(20)} ${vwMobile(5)};
    @media (min-width: ${media.xsup}) {
      padding: ${vwTablet(20)} ${vwTablet(5)};
    }
    @media (min-width: ${media.mdup}) {
      padding: ${vw(40)} ${vw(25)};
    }
  }
`;
