import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import media from './media';
import { vw, vwMobile, vwTablet } from './utils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: ${theme.fonts.main};
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.primary};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('/assets/fonts/Roboto-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Glosa Display Roman';
    src: url('/assets/fonts/GlosaDisplay-Roman.woff2') format('woff2'),
        url('/assets/fonts/GlosaDisplay-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  h1 {
    font-family: ${theme.fonts.header};
    font-weight: normal;
    font-size: ${vwMobile(18)};
    line-height: 1.56;
    letter-spacing: ${vwMobile(1)};
    text-transform: uppercase;
    color: ${theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(1)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(24)};
      letter-spacing: ${vw(1.1)};
    }
  }

  h2 {
    font-family: ${theme.fonts.header};
    font-weight: normal;
    font-size: ${vwMobile(16)};
    letter-spacing: ${vwMobile(0.4)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(0.4)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(0.4)};
    }
  }

  p {
    font-size: ${vwMobile(14)};
    font-weight: 300;
    line-height: 1.79;
    letter-spacing: ${vwMobile(0.7)};
    color: ${theme.color.darkBlue};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.7)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(0.8)};
      line-height: 1.75;
    }
  }

`;
