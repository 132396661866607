import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import Button from 'components/Button';

class Terms extends Component {
  render() {
    return (
      <Root>
        <h1>Privacy Policy</h1>
        <Section>
          <p className="lastChild">
            Origin Properties and each of our affiliates and partnerships is
            committed to maintaining the accuracy, confidentiality and security
            of your personal information. This Privacy Policy describes the
            personal information that we collect from or about you, how we use
            that information, and to whom we disclose that information.
          </p>
        </Section>
        <Section>
          <h2>Collecting Personal Information</h2>

          <p>
            We collect personal information to better serve your needs, whether
            purchasing products and services from us or obtaining service or
            warranty work. In order to provide you with our wide variety of
            services, products and promotions, we may collect the following
            information:
          </p>
          <ul>
            <li>
              Contacting and mailing information such as name, address,
              telephone numbers, fax numbers, and email addresses.
            </li>
            <li>
              The plans, specifications and other particulars about the home
              that is being build for you.
            </li>
            <li>Municipal and legal descriptions for your home.</li>
            <li>
              Any other relevant information necessary to provide you with our
              products and services.
            </li>
            <li>
              We may need to collect other personal information depending on the
              circumstance, but we will ask you directly and ensure that you
              agree.
            </li>
          </ul>
        </Section>

        <Section>
          <h2>Personal Information Security</h2>
          <p>
            Like you, we value our personal information. Part of valuing your
            personal information is making sure that it is protected and kept
            confidential. We achieve this by:
          </p>
          <ul>
            <li>Having our employees sign a confidentiality agreement.</li>
            <li>
              Having up to date computer security such as passwords, encryption
              and firewalls.
            </li>
            <li>
              Where third parties are hired to assist us with providing products
              and services to you for your home, we have them sign a privacy and
              confidentiality agreement to ensure that your personal information
              is protected by them.
            </li>
          </ul>
        </Section>

        <Section />

        <h2>Personal Information Updating</h2>
        <p>
          We only collect Personal Data from you, such as first and last name,
          phone number, e-mail, when you register to receive information. We
          collect data about your activities that does not personally or
          directly identify you when you visit our website, or the websites and
          online services where we display advertisements. This information may
          include the content you view, the date and time that you view this
          content, the products you purchase, or your location information
          associated with your IP address.
          <br />
          <br />
          We use the information we collect to serve you more relevant
          advertisements (referred to as “Retargeting”). We collect information
          about where you saw the ads we serve you and what ads you clicked on.
          As you browse the website, online ad networks we work with may place
          anonymous cookies on your computer, and use similar technologies, in
          order to understand your interests based on your (anonymous) online
          activities, and thus to tailor more relevant ads to you. If you do not
          wish to receive such tailored advertising, you can visit this page to
          opt out of most companies that engage in such advertising. (This will
          not prevent you from seeing ads; the ads simply will not be delivered
          through these targeting methods.)
          <br />
          <br />
          We do not target ads to you based on sensitive personal data, such as
          information related to race or ethnic origin, political opinions,
          religious beliefs or other beliefs of a similar nature, trade union
          membership, physical or mental health or condition or sexual life. We
          may target ads based on data provided by Advertisers or partners alone
          or in combination with the data we collect ourselves. Any data used to
          serve targeted advertisements is de-identified and is not used to
          personally or directly identify you. In order to allow us to reach the
          best inventory online, we work with third party advertising companies
          (our “Advertising Partners”) to help us recognize you and serve
          relevant advertisements to you when you visit a website or online
          service in their network. We also work with Advertising Partners who
          help us recognize you across different devices in order to show you
          relevant advertisements. Our Advertising Partners may collect
          information about your activities on our website, and other websites
          or online services in their networks. We also work with third party
          companies to assist us with website analytics such as evaluating the
          use and operation of our website so that we can continue to enhance
          the website and our services.
          <br />
        </p>
        <p className="lastChild">
          We and our third-party partners use session cookies and persistent
          cookies to make it easier for you to navigate and enhance the
          experience of our site, and to monitor and evaluate our website’s
          operation and use.
        </p>
        <Contact>
          <p className="lastChild">
            To modify or delete your personal information, or to opt out of
            future communications, please contact us at:
          </p>

          <p className="lastChild">
            Origin Properties
            <br />
            #202-1477 W Pender Street
            <br />
            Vancouver, BC
            <br />
            V6G 2S3
          </p>

          <p>
            <a
              href="tel:604-428-7959"
              target="_blank"
              rel="noopener noreferrer"
            >
              604.428.7959
            </a>
            <br />
            <a
              href="mailto:info@originproperties.ca"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@originproperties.ca
            </a>
          </p>
        </Contact>

        <Button to="/" text="Back to Home" />
      </Root>
    );
  }
}

export default Terms;

const Root = styled.div`
  max-width: 589px;
  padding: 18px;
  margin: auto;
  margin-bottom: 80px;
  @media (min-width: ${media.mdup}) {
    padding: 45px;
    margin-top: 160px;
    margin-bottom: 255px;
  }
  h1 {
    font-size: 20px;
    color: ${({ theme }) => theme.colorDarkBlueGrey};
    line-height: 23px;
    letter-spacing: 1.8px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 20px;
    @media (min-width: ${media.mdup}) {
      font-size: 24px;
      line-height: 1.42;
      margin-bottom: 35px;
      letter-spacing: 2.4px;
    }
  }
  h2 {
    text-transform: uppercase;
    margin-bottom: 20px;
    @media (min-width: ${media.mdup}) {
      font-size: 16px;
      opacity: 0.9;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0.4px;
    }
  }
  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.7px;
    text-align: left;
    margin-bottom: 10px;
    @media (min-width: ${media.mdup}) {
      font-size: 16px;
      line-height: 1.75;
      letter-spacing: 0.8px;
    }
    &.lastChild {
      margin-bottom: 20px;
      @media (min-width: ${media.mdup}) {
        margin-bottom: 40px;
      }
    }
  }
  ul {
    font-size: 14px;
    font-weight: 300;
    font-family: Lato;
    line-height: 22px;
    letter-spacing: 0.7px;
    color: #688281;
    margin: 0 15px;
    margin-bottom: 20px;
    @media (min-width: ${media.mdup}) {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.8px;
      margin-bottom: 40px;
    }
  }
  a {
    color: ${({ theme }) => theme.color.darkBlue};
  }
`;

const Section = styled.div``;

const Contact = styled.div`
  margin-top: 10px;
  margin-bottom: 65px;
`;
