import React, { Component } from 'react';
import styled from 'styled-components';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import { connect } from 'react-redux';

import data from 'src/data/map';

class Map extends Component {
  state = {
    activeSection: 0,
    activePoint: null,
  };

  handleSection = index =>
    this.setState({ activeSection: index, activePoint: null });

  handlePoint = (index, number) => {
    let point = document.getElementById(number);
    if (!this.props.browser.is.desktop) {
      point.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
    return this.setState({ activePoint: index });
  };

  render() {
    return (
      <Root>
        <Container>
          <div className="map-container">
            <img
              src={require('src/assets/images/lifestyle/arbutus-illustrated-map.png')}
              alt=""
              className="map"
            />
            {data[this.state.activeSection].points.map((item, index) => (
              <Point
                left={item.left}
                top={item.top}
                number={item.number}
                active={index === this.state.activePoint}
                key={index}
                onClick={() => this.handlePoint(index, item.number)}
                id={item.number}
              >
                <div className="popup">{item.name}</div>
              </Point>
            ))}
            <img
              src={require('src/assets/images/icons/arbutus-location.png')}
              alt="The Arbutus"
              className="arbutus-pin"
            />
          </div>
        </Container>
        <Selection>
          {data.map((item, index) => (
            <Section key={index} active={index === this.state.activeSection}>
              <h2 onClick={() => this.handleSection(index)}>
                {item.title}
                <Icon active={index === this.state.activeSection}>
                  <span />
                  <span />
                </Icon>
              </h2>
              <div className="points">
                {item.points.map((point, pointIndex) => (
                  <Item
                    key={pointIndex}
                    active={pointIndex === this.state.activePoint}
                    onClick={() => this.handlePoint(pointIndex, point.number)}
                  >
                    <span>{point.number}.</span> {point.name}
                  </Item>
                ))}
              </div>
            </Section>
          ))}
        </Selection>
      </Root>
    );
  }
}

const mapStateToProps = state => ({
  browser: state.browser,
});

export default connect(
  mapStateToProps,
  {}
)(Map);

const Root = styled.div`
  margin-bottom: ${vwMobile(20)};
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    display: flex;
    margin-bottom: ${vw(80)};
  }
`;

const Container = styled.div`
  width: 100%;
  height: ${vwMobile(448)};
  position: relative;
  overflow: auto;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(600)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(997)};
    height: ${vw(768)};
    order: 2;
    overflow: hidden;
  }
  .map-container {
    width: ${vwMobile(800)};
    height: ${vwMobile(616)};
    position: relative;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(800)};
      height: ${vwTablet(616)};
    }
    @media (min-width: ${media.mdup}) {
      width: 100%;
      height: 100%;
    }
  }
  .map {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 80%;
    @media (min-width: ${media.mdup}) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .arbutus-pin {
    position: absolute;
    height: ${vwMobile(39)};
    width: ${vwMobile(50)};
    top: 64.6%;
    left: 46.5%;
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(39)};
      width: ${vwTablet(50)};
      top: 64.5%;
    }
    @media (min-width: ${media.mdup}) {
      top: 65.3%;
      height: ${vw(39)};
      width: ${vw(50)};
    }
  }
`;

const Point = styled.div`
  position: absolute;
  width: ${props => (props.active ? vwMobile(29) : vwMobile(23))};
  height: ${props => (props.active ? vwMobile(39) : vwMobile(41))};
  top: ${props => `${props.top - 1}%`};
  left: ${props => `${props.left - 0.2}%`};
  background-image: ${props =>
    props.active
      ? `url(${require('src/assets/images/icons/location-pin-active.svg')})`
      : `url(${require('src/assets/images/icons/location-pin.svg')})`};
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: ${props => (props.active ? 2 : 1)};
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    width: ${props => (props.active ? vwTablet(29) : vwTablet(23))};
  height: ${props => (props.active ? vwTablet(39) : vwTablet(41))};
  }
  @media (min-width: ${media.mdup}) {
    top: ${props => `${props.top}%`};
  left: ${props => `${props.left}%`};
    width: ${props => (props.active ? vw(29) : vw(23))};
    height: ${props => (props.active ? vw(39) : vw(41))};
  }
  &::before {
    content: '${props => props.number}';
    font-size: ${vwMobile(14)};
    font-family: ${({ theme }) => theme.fonts.header};
    letter-spacing: ${vwMobile(0.4)};
    color: ${props => (props.active ? props.theme.color.darkBlue : 'white')};
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    transition: 0.3s ease;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.4)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(0.4)};
    }
  }
  .popup {
    pointer-events: none;
    opacity: ${props => (props.active ? 1 : 0)};
    background-color: white;
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(10)};
    font-weight: normal;
    letter-spacing: ${vwMobile(0.83)};
    text-align: center;
    color: ${({ theme }) => theme.color.darkBlue};
    padding: ${vwMobile(9)};
    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: ${vwMobile(-4)};
    transform: translate(-50%, -100%);
    transition: 0.3s ease;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(0.83)};
      padding: ${vwTablet(9)};
      top: ${vwTablet(-4)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(10)};
      letter-spacing: ${vw(0.83)};
      padding: ${vw(9)};
      top: ${vw(-4)};
    }
  }
`;

const Selection = styled.div`
  background-color: #b9d5d2;
  width: 100%;
  padding-top: ${vwMobile(31)};
  overflow: scroll;
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(31)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(391)};
    height: ${vw(768)};
    order: 1;
    padding-top: ${vw(77)};
  }
`;

const Section = styled.div`
  color: ${({ theme }) => theme.color.darkBlue};
  overflow: hidden;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props =>
      props.active ? 'rgba(255, 255, 255, 0.27)' : 'transparent'};
    text-transform: uppercase;
    font-weight: normal;
    font-size: ${vwMobile(14)};
    letter-spacing: ${vwMobile(0.4)};
    cursor: pointer;
    padding: ${vwMobile(19)} ${vwMobile(15)};
    transition: 0.3s ease;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.4)};
      padding: ${vwTablet(19)} ${vwTablet(15)};
    }
    @media (min-width: ${media.mdup}) {
      padding: ${vw(20)} ${vw(20)} ${vw(20)} ${vw(56)};
      font-size: ${vw(16)};
      letter-spacing: ${vw(0.8)};
      &:hover {
        background-color: rgba(255, 255, 255, 0.27);
      }
    }
  }
  p {
    cursor: pointer;
    @media (min-width: ${media.mdup}) {
      transition: 0.3s ease;
      &:hover {
        font-weight: 500;
      }
    }
  }
  .points {
    max-height: ${props => (props.active ? '600px' : 0)};
    transition: 0.5s ease;
    padding: 0 ${vwMobile(15)};
    margin-top: ${props => (props.active ? vwMobile(15) : 0)};
    @media (min-width: ${media.xsup}) {
      margin-top: ${props => (props.active ? vwTablet(15) : 0)};
    }
    @media (min-width: ${media.mdup}) {
      padding: 0 ${vw(66)};
      margin-top: ${props => (props.active ? vw(15) : 0)};
    }
  }
`;

const Icon = styled.div`
  width: 17.5px;
  height: 17.5px;
  padding-top: 7px;
  span {
    width: 17.5px;
    height: 1px;
    display: block;
    background-color: ${({ theme }) => theme.color.darkBlue};
    transform: translateY(50%);
    transition: 0.3s ease;
    &:last-child {
      transform: rotate(90deg);
      transform: ${props => (props.active ? 'rotate(0)' : 'rotate(90deg)')};
    }
    &:first-child {
      opacity: ${props => (props.active ? 0 : 1)};
    }
  }
`;

const Item = styled.p`
  display: flex;
  cursor: pointer;
  font-weight: ${props => (props.active ? 'normal' : '300')};
  span {
    padding-right: 5px;
  }
`;
