import React, { Component } from 'react';

import Page from 'components/Page';
import Hero from './elements/Hero';
import Content from './sections/Content';
import Nextbar from 'components/Nextbar';

class Home extends Component {

  render() {
    return (
      <Page>
        <Hero />
        <Content />
        <Nextbar text="Next: Floorplans" to="/floorplans" />
      </Page>
    );
  }
}

export default Home;
