export default [
  {
    id: '201',
    interior: '768',
    balcony: '354',
    total: '1122',
    bed: 2,
    den: false,
  },
  {
    id: '202',
    interior: '981',
    balcony: '301',
    total: '1282',
    bed: 2,
    den: false,
  },
  {
    id: '203-303',
    interior: '751-754',
    balcony: '47',
    total: '798-801',
    bed: 1,
    den: false,
  },
  {
    id: '204',
    interior: '957',
    balcony: '49',
    total: '1006',
    bed: 2,
    den: false,
  },
  {
    id: '205-305',
    interior: '691',
    balcony: '40',
    total: '731',
    bed: 1,
    den: true,
  },
  {
    id: '206-306',
    interior: '527',
    balcony: '32',
    total: '559',
    bed: 1,
    den: false,
  },
  {
    id: '207-307',
    interior: '639',
    balcony: '32',
    total: '671',
    bed: 1,
    den: true,
  },
  {
    id: '301',
    interior: '762',
    balcony: '165',
    total: '927',
    bed: 2,
    den: false,
  },
  {
    id: '302',
    interior: '960',
    balcony: '131',
    total: '1091',
    bed: 2,
    den: false,
  },
  {
    id: '304',
    interior: '997',
    balcony: '53',
    total: '1050',
    bed: 2,
    den: false,
  },
  {
    id: '401',
    interior: '857',
    balcony: '129',
    roof: '732',
    total: '1718',
    bed: 2,
    den: false,
  },
  {
    id: '402',
    interior: '1041',
    balcony: '139',
    roof: '874',
    total: '2054',
    bed: 2,
    den: true,
  },
  {
    id: '403',
    interior: '820',
    roof: '684',
    total: '1504',
    bed: 2,
    den: false,
  },
  {
    id: '404',
    interior: '1051',
    roof: '1008',
    total: '2059',
    bed: 2,
    den: true,
  },
  {
    id: '405',
    interior: '957',
    roof: '724',
    total: '1681',
    bed: 2,
    den: false,
  },
  {
    id: '406',
    interior: '857',
    roof: '594',
    total: '1451',
    bed: 2,
    den: true,
  },
];
