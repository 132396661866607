import React from 'react';
import styled from 'styled-components';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import { Parallax } from 'react-scroll-parallax';
import { connect } from 'react-redux';

import ParallaxImage from 'components/ParallaxImage';

const Content = props => {
  return (
    <Root>
      <img
        src={require('src/assets/images/icons/left-leaf.svg')}
        alt="Leaf"
        className="leaf"
      />
      <Living>
        <h1>Dynamic Living</h1>
        <p>
          Complementing homes that are already richly intricate, each home at
          The Arbutus has been meticulously crafted for a life of effortless
          style and enduring elegance. Spacious and contemporary interiors that
          are filled with shimmering natural light feature functional and
          practical design features to create homes of exceptional quality.
        </p>
        <Parallax y={[10, -10]} disabled={!props.browser.is.desktop}>
          <StyledParallaxImage
            src={require('src/assets/images/renderings/livingroom.jpg')}
            alt="Dynamic Living"
          />
        </Parallax>
      </Living>
      <Style>
        <Parallax y={[10, -10]} disabled={!props.browser.is.desktop}>
          <ParallaxImage
            src={require('src/assets/images/renderings/rooftop-terrace.jpg')}
            alt="Rooftop Terrace"
            className="top"
          />
        </Parallax>
        <div className="background">
          <h1>Illuminating Style</h1>
          <p>
            Iconic in form, timeless in approach and elegant in every sense, the
            kitchens and bathrooms of every Arbutus home features the renowned
            style of Scavolini. Defined by clean, contemporary lines, the
            high-end materials and Italian craftsmanship combines refined
            aesthetics with practical functionality you can use every day.
          </p>
        </div>
        <Parallax y={[-5, 5]} disabled={!props.browser.is.desktop}>
          <ParallaxImage
            src={require('src/assets/images/renderings/ensuite-bath.jpg')}
            alt="Ensuite Bath"
            className="bottom"
          />
        </Parallax>
      </Style>
    </Root>
  );
};

const mapStateToProps = state => ({
  browser: state.browser,
});

export default connect(
  mapStateToProps,
  {}
)(Content);

const Root = styled.div`
  position: relative;
  h1 {
    margin-bottom: ${vwMobile(24)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(24)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(30)};
    }
  }
  .leaf {
    width: ${vw(109)};
    height: ${vw(360)};
    position: absolute;
    left: ${vw(-28)};
    display: none;
    z-index: 2;
    @media (min-width: ${media.mdup}) {
      display: block;
    }
  }
`;

const Living = styled.div`
  margin: ${vwMobile(50)} 0 ${vwMobile(10)};
  text-align: center;
  @media (min-width: ${media.xsup}) {
    margin: ${vwTablet(50)} 0 ${vwTablet(24)};
  }
  @media (min-width: ${media.mdup}) {
    margin: ${vw(65)} 0 ${vw(240)};
  }
  /* img {
    width: 100%;
    height: ${vwMobile(310)};
    object-fit: cover;
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(410)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(691)};
    }
  } */
  p {
    width: ${vwMobile(299)};
    margin: 0 auto ${vwMobile(50)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(384)};
      margin: 0 auto ${vwTablet(50)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(553)};
      margin: 0 auto ${vw(65)};
    }
  }
`;

const StyledParallaxImage = styled(ParallaxImage)`
  width: 100%;
  height: ${vwMobile(310)};
  object-fit: cover;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(410)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(691)};
  }
`;

const Style = styled.div`
  text-align: center;
  position: relative;
  @media (min-width: ${media.mdup}) {
    max-height: ${vw(619)};
    background-color: #b9d5d2;
    text-align: left;
    margin-bottom: ${vw(270)};
    background-image: url(${require('src/assets/images/lifestyle/leaf-bg.svg')});
    background-position: top left;
    background-repeat: no-repeat;
  }
  img {
    width: 100%;
    height: ${vwMobile(310)};
    object-fit: cover;
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(410)};
    }
  }
  .top {
    @media (min-width: ${media.mdup}) {
      width: ${vw(761)};
      height: ${vw(404)};
      margin-left: ${vw(571)};
      margin-top: ${vw(-154)};
    }
  }
  .bottom {
    @media (min-width: ${media.mdup}) {
      width: ${vw(419)};
      height: ${vw(484)};
      margin-left: ${vw(47)};
      margin-top: ${vw(-154)};
    }
  }
  .background {
    background-color: #b9d5d2;
    margin: ${vwMobile(10)} 0;
    padding: ${vwMobile(60)} ${vwMobile(26)};
    background-image: url(${require('src/assets/images/lifestyle/leaf-bg.svg')});
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 90%;
    @media (min-width: ${media.xsup}) {
      margin: ${vwTablet(24)} 0;
      padding: ${vwTablet(60)} ${vwTablet(26)};
      background-size: 50%;
    }
    @media (min-width: ${media.mdup}) {
      padding: 0;
      width: auto;
      margin: ${vw(69)} 0 0 ${vw(568)};
      background-color: transparent;
      background-image: none;
    }
  }
  p {
    @media (min-width: ${media.xsup}) {
      max-width: ${vwTablet(384)};
      margin: 0 auto;
    }
    @media (min-width: ${media.mdup}) {
      margin: 0;
    }
  }
`;
