import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const TypeSelect = ({ handlePlan, data, isPlanActive }) => {
  return (
    <Root id="type-select">
      {data.map((item, index) => (
        <Plan
          key={index}
          active={isPlanActive(item.bed)}
          onClick={() => handlePlan(index)}
        >
          <h4>Plan {item.id.toUpperCase().replace('-', '/')}</h4>
          <p>
            {item.bed} Bed {item.den && '+ Den'} {item.roof && '+ Roof Deck'}
          </p>
          <p>{item.total} sqft</p>
        </Plan>
      ))}
    </Root>
  );
};

TypeSelect.propTypes = {
  handlePlan: PropTypes.func,
  data: PropTypes.array,
  isPlanActive: PropTypes.func,
};

export default TypeSelect;

const Root = styled.div`
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(83)};
    height: ${vw(481)};
    overflow-y: scroll;
    width: ${vw(774)};
    padding-right: ${vw(40)};
    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${({ theme }) => theme.color.silver};
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.darkBlue};
      border-radius: 5px;
    }
  }
`;

const Plan = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${props =>
    props.active ? `1px solid ${props.theme.color.darkBlue}` : `0px solid`};
  margin-bottom: ${props => (props.active ? vwMobile(5) : 0)};
  height: ${vwMobile(35)};
  padding: 0 ${vwMobile(6)};
  overflow: hidden;
  max-height: ${props => (props.active ? vwMobile(35) : '0px')};
  transition: max-height 0.3s ease, margin 0.3s ease, background-color 0.3s ease;
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${props => (props.active ? vwTablet(5) : 0)};
    height: ${vwTablet(35)};
    padding: 0 ${vwTablet(6)};
    max-height: ${props => (props.active ? vwTablet(35) : '0px')};
  }
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${props => (props.active ? vw(5) : 0)};
    height: ${vw(56)};
    padding: 0 ${vw(30)};
    max-height: ${props => (props.active ? vw(56) : '0px')};
    cursor: pointer;
    &:hover {
      background-color: white;
    }
  }
  h4 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vwMobile(14)};
    font-weight: normal;
    color: ${({ theme }) => theme.color.earth};
    opacity: ${props => (props.active ? 1 : 0)};
    transition: 0.3s ease;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(26)};
      width: 45%;
    }
  }
  p {
    font-size: ${vwMobile(10)};
    font-weight: bold;
    letter-spacing: ${vwMobile(0.6)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.blueGray};
    opacity: ${props => (props.active ? 1 : 0)};
    transition: 0.3s ease;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(0.6)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(10)};
      letter-spacing: ${vw(1.8)};
      width: 30%;
      &:last-child {
        width: 25%;
        text-align: right;
      }
    }
  }
`;
