export default {
  priceRange: {
    question: 'What is your price range?',
    options: [
      {
        title: '$100,000-$200,000',
        value: '$100,000-$200,000',
      },
      { title: '$200,000-$300,000', value: '$200,000-$300,000' },
    ],
  },
  bedrooms: {
    question: 'How many bedrooms do you prefer?',
    options: [
      {
        title: '1',
        value: '1',
      },
      { title: '2', value: '2' },
      { title: '3', value: '3' },
    ],
  },
  realtor: {
    question: 'Are you a realtor?',
    options: [
      { title: 'Yes', value: 'Yes' },
      { title: 'No', value: 'No' },
    ],
  },
  workingWithRealtor: {
    question: 'Are you working with a realtor?',
    options: [
      { title: 'Yes', value: 'Yes' },
      { title: 'No', value: 'No' },
    ],
  },
  langPreference: {
    question: 'Language Preference?',
    options: [
      {
        title: 'English',
        value: 'English',
      },
      {
        title: 'Mandarin',
        value: 'Mandarin',
      },
      {
        title: 'Cantonese',
        value: 'Cantonese',
      },
    ],
  },
  howHeard: {
    question: 'How did you hear about us?',
    options: [
      {
        title: 'Signage/Drive by',
        value: 'Signage/Drive by',
      },
      {
        title: 'Word of Mouth',
        value: 'Word of Mouth',
      },
      {
        title: 'Social Media(Facebook/Instagram)',
        value: 'Social Media(Facebook/Instagram)',
      },
      {
        title: 'Google Search',
        value: 'Google Search',
      },
      {
        title: 'Online Advertisement',
        value: 'Online Advertisement',
      },
      {
        title: 'WeChat',
        value: 'WeChat',
      },
      {
        title: 'BuzzBuzz Home',
        value: 'BuzzBuzz Home',
      },
      {
        title: 'Transit Advertisement',
        value: 'Transit Advertisement',
      },
      {
        title: 'Realtor',
        value: 'Realtor',
      },
      {
        title: 'Friend or Family',
        value: 'Friend or Family',
      },
    ],
  },
  promotional:
    "Yes, I'd like to receive additional promotional emails from Origin Properties.",
  terms:
    'By clicking Register you agree to our Terms that you have read and our Privacy Policy',
};
