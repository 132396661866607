import React from 'react';
import styled from 'styled-components';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

//Custom checkbox from: https://www.w3schools.com/howto/howto_css_custom_checkbox.asp

const Checkbox = ({ text, name, value }) => (
  <Root>
    <label className="container form-label">
      {text}
      <input type="checkbox" name={name} value={value} />
      <span className="checkmark" />
    </label>
  </Root>
);

export default Checkbox;

const Root = styled.div`
  margin-bottom: ${vwMobile(30)};
  padding-right: ${vwMobile(20)};
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(30)};
    padding-right: 0;
  }
  @media (min-width: ${media.mdup}) {
    margin-right: ${vw(10)};
    margin-bottom: ${vw(30)};
  }
  label {
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: ${vwMobile(14)};
    font-weight: 300;
    color: ${({ theme }) => theme.color.text};
    opacity: 0.75;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
    }
  }
  /* Customize the label (the container) */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1px solid rgba(36, 36, 36, 0.75);
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #b9d5d0;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
