import React, { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { vwMobile, vwTablet, vw } from 'src/styles/utils';
import { Link } from 'react-router-dom';
import HamburgerMenu from 'react-hamburger-menu';
import media from 'src/styles/media';

const links = [
  {
    to: '/',
    name: 'Home',
  },
  {
    to: '/floorplans',
    name: 'Floorplans',
  },
  {
    to: '/building',
    name: 'Building',
  },
  {
    to: '/lifestyle',
    name: 'Lifestyle',
  },
  {
    to: '/team',
    name: 'Team',
  },
  {
    to: '/contact',
    name: 'Contact',
  },
];

class Navigation extends Component {
  state = {
    isOpen: false,
    scrollingDown: false,
  };

  componentDidMount() {
    window.addEventListener('wheel', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.handleScroll);
  }

  componentWillReceiveProps(prevProps) {
    // makes header reappear on page transition
    if (this.props.location.pathname !== prevProps.location.pathName) {
      this.setState({ scrollingDown: false });
    }
  }

  handleScroll = e => {
    let floorplans = document.getElementById('type-select');
    let delta;

    // if the typeselect on floorplans is active and scrolling, dont let the header disappear
    if (floorplans) {
      floorplans.onscroll = function() {
        return this.setState({ scrollingDown: false });
      }.bind(this);
    }

    if (e.wheelDelta) {
      delta = e.wheelDelta;
    } else {
      delta = -1 * e.deltaY;
    }

    if (delta < 0) {
      this.setState({ scrollingDown: true });
    } else if (delta > 0) {
      this.setState({ scrollingDown: false });
    }
  };

  handleMenu = () => this.setState({ isOpen: !this.state.isOpen });

  closeMenu = () => this.setState({ isOpen: false });

  render() {
    return (
      <Root scrollingDown={this.state.scrollingDown}>
        <Mobile onClick={this.handleMenu}>
          <HamburgerMenu
            isOpen={this.state.isOpen}
            menuClicked={this.handleMenu}
            width={17}
            height={10}
            strokeWidth={1}
            rotate={0}
            color="white"
            borderRadius={0}
            animationDuration={0.3}
          />
          <StyledLink to="/register" onClick={this.closeMenu}>
            Register Now
          </StyledLink>
        </Mobile>
        <Menu isOpen={this.state.isOpen}>
          <Logo to="/">
            <img
              src={require('src/assets/images/logos/arbutus-logo-small.svg')}
              alt="The Arbutus"
            />
          </Logo>
          <Links>
            {links.map((item, index) => (
              <MenuLink
                to={item.to}
                key={index}
                index={index}
                isopen={this.state.isOpen.toString()}
                onClick={this.closeMenu}
                className={item.name === 'Home' && 'home'}
                active={window.location.pathname === item.to}
              >
                {item.name}
              </MenuLink>
            ))}
            <MenuLink
              className="register"
              to="/register"
              active={window.location.pathname === '/register'}
            >
              Register Now
            </MenuLink>
          </Links>
        </Menu>
      </Root>
    );
  }
}

export default Navigation;

const Root = styled.div`
  position: fixed;
  bottom: ${vwMobile(10)};
  width: ${`calc(100% - ${vwMobile(20)})`};
  z-index: 100;
  margin: 0 ${vwMobile(10)};
  @media (min-width: ${media.mdup}) {
    bottom: inherit;
    top: ${vw(15)};
    top: ${props => (props.scrollingDown ? vw(-80) : vw(15))};
    width: ${`calc(100% - ${vw(56)})`};
    height: ${vw(60)};
    margin: 0;
    left: ${vw(28)};
    transition: 0.5s ease;
  }
`;

const Mobile = styled.div`
  height: ${vwMobile(30)};
  max-height: ${vwMobile(30)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.darkBlue};
  padding: ${vwMobile(9)};
  z-index: 3;
  position: relative;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(30)};
    max-height: ${vwTablet(30)};
    padding: ${vwTablet(9)};
  }
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: ${vwMobile(11)};
  font-weight: bold;
  letter-spacing: ${vwMobile(1)};
  text-transform: uppercase;
  line-height: 1;
  color: ${({ theme }) => theme.color.desert};
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(12)};
    letter-spacing: ${vwTablet(1)};
  }
`;

const Logo = styled(Link)`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    max-height: 100%;
    img {
      max-height: ${vw(45)};
      object-fit: contain;
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.darkBlue};
  max-height: ${props => (props.isOpen ? '500px' : 0)};
  transition: 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  display: flex;
  flex-direction: column;
  margin-bottom: ${vwMobile(30)};
  z-index: 2;
  overflow: hidden;
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(30)};
  }
  @media (min-width: ${media.mdup}) {
    max-height: none;
    height: ${vw(60)};
    margin-bottom: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${vw(7)} ${vw(75)};
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
  }
`;

const MenuAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const menuAnimationCss = props => css`
  animation: ${MenuAnimation} 0.3s ease forwards;
`;

const MenuLink = styled(Link)`
  padding: ${vwMobile(10)};
  color: white;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: ${vwMobile(11)};
  font-weight: 300;
  letter-spacing: ${vwMobile(0.3)};
  text-transform: uppercase;
  opacity: 0;
  ${props => (props.isopen === 'true' ? menuAnimationCss : 'animation: none;')};
  animation-delay: ${props => `${0.1 + props.index * 0.2}s`};
  transition: opacity 1s ease;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(10)};
    font-size: ${vwTablet(12)};
    letter-spacing: ${vwTablet(0.3)};
  }
  @media (min-width: ${media.mdup}) {
    opacity: 1;
    animation: none;
    animation-delay: none;
    padding: 0;
    font-size: ${vw(16)};
    letter-spacing: ${vw(0.5)};
    line-height: 1.75;
    margin-left: ${vw(46)};
    transition: all 0.3 ease;
    font-weight: ${props => (props.active ? '500' : '300')};
    &.home {
      display: none;
    }
    &:hover {
      font-weight: 500;
    }
  }
  &.register {
    display: none;
    @media (min-width: ${media.mdup}) {
      display: block;
      font-weight: normal;
      color: ${({ theme }) => theme.color.desert};
      font-weight: ${props => (props.active ? '700' : '500')};
      &:hover {
        font-weight: bold;
      }
    }
  }
  &:first-of-type {
    padding-top: ${vwMobile(23)};
    @media (min-width: ${media.xsup}) {
      padding-top: ${vwTablet(23)};
    }
    @media (min-width: ${media.mdup}) {
      padding-top: 0;
    }
  }
  &:last-of-type {
    padding-bottom: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      padding-bottom: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      padding-bottom: 0;
    }
  }
`;
