import React from 'react';
import styled from 'styled-components';
import TwentyTwenty from 'react-twentytwenty';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Features = () => {
  return (
    <Root>
      <img
        src={require('src/assets/images/icons/right-leaf.svg')}
        alt="Leaf"
        className="leaf"
      />
      <h1>Features and Finishes</h1>
      <p className="desktop">
        Slide to compare the finishes available at The Arbutus.
      </p>
      <Mobile>
        <img
          src={require('src/assets/images/renderings/kitchen-light.jpg')}
          alt="Light Scheme"
        />
        <h3>Light Scheme</h3>
        <img
          src={require('src/assets/images/renderings/kitchen-dark.jpg')}
          alt="Light Scheme"
        />
        <h3>Dark Scheme</h3>
      </Mobile>
      <Desktop>
        <TwentyTwenty
          left={
            <img
              src={require('src/assets/images/renderings/kitchen-light.jpg')}
              alt="Light Scheme"
            />
          }
          right={
            <img
              src={require('src/assets/images/renderings/kitchen-dark.jpg')}
              alt="Dark Scheme"
            />
          }
          slider={<Slider />}
        />
        <Text>
          <h3>Light Scheme</h3>
          <h3>Dark Scheme</h3>
        </Text>
      </Desktop>
      <Button href="/assets/pdf/Arbutus_A_Kit_Featuresheet.pdf" target="_blank">
        Download Featuresheet
      </Button>
    </Root>
  );
};

export default Features;

const Root = styled.div`
  position: relative;
  h1 {
    text-align: center;
    margin: ${vwMobile(50)} 0;
    @media (min-width: ${media.xsup}) {
      margin: ${vwTablet(50)} 0;
    }
    @media (min-width: ${media.mdup}) {
      margin: ${vw(20)} 0;
    }
  }
  .desktop {
    display: none;
    @media (min-width: ${media.mdup}) {
      display: block;
      text-align: center;
    }
  }
  .leaf {
    position: absolute;
    right: ${vw(-28)};
    top: ${vw(-50)};
    z-index: 2;
    width: ${vw(115)};
    height: ${vw(394)};
    display: none;
    @media (min-width: ${media.mdup}) {
      display: block;
    }
  }
`;

const Mobile = styled.div`
  @media (min-width: ${media.mdup}) {
    display: none;
  }
  img {
    width: 100%;
    height: ${vwMobile(310)};
    object-fit: cover;
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(410)};
    }
  }
  h3 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vwMobile(14)};
    font-weight: normal;
    letter-spacing: ${vwMobile(0.4)};
    text-transform: uppercase;
    text-align: center;
    color: ${({ theme }) => theme.color.text};
    margin-bottom: ${vwMobile(25)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.4)};
      margin-bottom: ${vwTablet(25)};
    }
  }
`;

const Desktop = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    width: ${vw(1102)};
    height: ${vw(662)};
    margin: ${vw(43)} auto ${vw(65)};
  }
  img {
    width: 100%;
    height: 100%;
    width: ${vw(1102)};
    height: ${vw(662)};
  }
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  h3 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-size: ${vw(21)};
    font-weight: normal;
    line-height: 1.62;
    letter-spacing: ${vw(2.1)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.darkBlue};
    &:last-of-type {
      text-align: right;
    }
  }
`;

const Slider = styled.div`
  width: 2px;
  height: 99.5%;
  background-color: ${({ theme }) => theme.color.darkBlue};
  position: relative;
  &::after {
    content: '';
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    background-image: url(${require('src/assets/images/icons/slider.svg')});
    background-size: cover;
  }
`;

const Button = styled.a`
  width: ${vwMobile(254)};
  height: ${vwMobile(39)};
  margin: ${vwMobile(45)} auto ${vwMobile(35)};
  background-color: ${({ theme }) => theme.color.silver};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${vwMobile(10)};
  font-weight: bold;
  letter-spacing: ${vwMobile(1.8)};
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.color.blueGray};
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(254)};
    height: ${vwTablet(39)};
    margin: ${vwTablet(45)} auto ${vwTablet(35)};
    font-size: ${vwTablet(10)};
    letter-spacing: ${vwTablet(1.8)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(254)};
    height: ${vw(39)};
    margin: ${vw(45)} auto ${vw(100)};
    font-size: ${vw(10)};
    letter-spacing: ${vw(1.8)};
  }
`;
