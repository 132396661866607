import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import Page from 'components/Page';
import Button from 'components/Button';

const Thankyou = () => {
  return (
    <Page>
      <Content>
        <h1>Thank you</h1>
        <p>
          We have received your submissions. A member of our team will be in
          touch shortly with next steps. If you need to get in touch with us
          please <a href="mailto:info@thearbutusliving.ca">email</a> us and we
          will be happy to answer any questions.
        </p>
        <StyledButton text="Back to Home" to="/" />
      </Content>
    </Page>
  );
};

export default Thankyou;

const Content = styled.div`
  margin: ${vw(150)} auto;
  text-align: center;
  @media (min-width: ${media.xsup}) {
    max-width: ${vwTablet(500)};
  }
  @media (min-width: ${media.mdup}) {
    max-width: ${vw(600)};
  }
  p {
    margin: ${vwMobile(20)} 0 ${vwMobile(40)};
    @media (min-width: ${media.xsup}) {
      margin: ${vwTablet(20)} 0 ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      margin: ${vw(20)} 0 ${vw(40)};
    }
  }
  a {
    color: ${({ theme }) => theme.color.earth};
  }
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;
