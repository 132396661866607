import React from 'react';
import styled from 'styled-components';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Input = ({ htmlId, label, required, type, width, pattern, name }) => (
  <Root width={width}>
    <Label htmlFor={htmlId} className="form-label">
      {label}
    </Label>
    <input
      type={type}
      id={htmlId}
      required={required}
      pattern={pattern}
      name={name}
    />
  </Root>
);

export default Input;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => `${props.width}%`};

  input {
    border: 1px solid rgba(36, 36, 36, 0.74);
    height: ${vwMobile(40)};
    margin-bottom: ${vwMobile(24)};
    padding: ${vwMobile(12)} ${vwMobile(4)};
    font-size: ${vwMobile(14)};
    color: ${({ theme }) => theme.colorBlack};
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(40)};
      margin-bottom: ${vwTablet(24)};
      padding: ${vwTablet(12)} ${vwTablet(4)};
      font-size: ${vwTablet(14)};
    }
    @media (min-width: ${media.mdup}) {
      margin-right: ${vw(10)};
      height: ${vw(40)};
      margin-bottom: ${vw(24)};
      padding: ${vw(12)} ${vw(4)};
      font-size: ${vw(14)};
    }
  }
`;

const Label = styled.label`
  opacity: 0.75;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  padding-left: ${vwMobile(4)};
  padding-bottom: ${vwMobile(9)};
  @media (min-width: ${media.xsup}) {
    padding-left: ${vwTablet(4)};
    padding-bottom: ${vwTablet(9)};
  }
  @media (min-width: ${media.mdup}) {
    padding-left: ${vw(4)};
    padding-bottom: ${vw(9)};
  }
`;
