import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

class Button extends Component {
  render() {
    return (
      <Root>
        <Rectangle>
          <span>Register</span>
        </Rectangle>
      </Root>
    );
  }
}

export default Button;

const Root = styled.button`
  background-color: ${({ theme }) => theme.color.darkBlue};
  height: ${vwMobile(48)};
  width: ${vwMobile(180)};
  padding: ${vwMobile(8)};
  margin: 0 auto;
  cursor: pointer;
  display: block;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(48)};
    width: ${vwTablet(180)};
    padding: ${vwTablet(8)};
  }
  @media (min-width: ${media.smup}) {
    &:hover {
      background-color: white;
      div {
        border: 1px solid ${({ theme }) => theme.color.darkBlue};
      }
      span {
        color: ${({ theme }) => theme.color.darkBlue};
      }
    }
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(48)};
    width: ${vw(180)};
    padding: ${vw(8)};
  }
`;

const Rectangle = styled.div`
  border: 1px solid white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    text-transform: uppercase;
    font-size: ${vwMobile(14)};
    font-weight: normal;
    font-family: ${({ theme }) => theme.fonts.header};
    letter-spacing: ${vwMobile(0.5)};
    color: white;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.5)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(0.5)};
    }
  }
`;
