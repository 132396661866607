import React, { Component } from 'react';
import styled from 'styled-components';

export default class Header extends Component {
  render() {
    return (
      <Root>
        <h1>header</h1>
      </Root>
    );
  }
}

const Root = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  background: #333;
  z-index: 100;
  text-align: center;
  nav {
    margin-top: 10px;
    text-align: center;
  }
  a {
    color: ${({ theme }) => theme.color.primary};
    margin: 0 15px;
  }
`;
