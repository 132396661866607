export default [
  {
    title: 'Effortless Style',
    page: 'floorplans',
    to: '/floorplans',
    text:
      'Spacious and contemporary interiors that are filled with shimmering natural light feature functional and practical design features to create homes of exceptional quality.',
    linkText: 'Learn more about the floorplans',
    image: require('src/assets/images/renderings/livingroom.jpg'),
  },
  {
    title: 'Urban Convenience & Suburban Cool',
    page: 'lifestyle',
    to: '/lifestyle',
    text:
      'Endless beaches and iconic mountain views, —what makes Kitsilano famous is merely the beginning of what makes it such an incredible place to live.',
    linkText: 'Learn more about Kitsilano',
    image: require('src/assets/images/lifestyle/arbutus-115-min.jpg'),
  },
  {
    title: 'A Wonderfully Natural Perspective',
    page: 'building',
    to: '/building',
    text:
      'The Arbutus is veiled in flora, giving you a wonderfully natural perspective of the world that surrounds you, and a magical spectrum that fills every home with serene light.',
    linkText: 'Learn more about the building',
    image: require('src/assets/images/renderings/street-winter.jpg'),
  },
];
